import React, { useState, useEffect } from 'react';

import {
  List,
  Icon,
  ListItem as MuiListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  MenuItem as MuiMenuItem,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';


import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import styled from '@emotion/styled';


import { useHistory, useLocation } from 'react-router-dom';

import Menu from 'material-ui-popup-state/HoverMenu';
import {
  usePopupState,
  bindHover,
  bindMenu,
} from 'material-ui-popup-state/hooks';

import { useSelector } from '~/store';

import { listItem, menuItem } from './menuItemDrawer.style';

interface SubMenuProps {
  description: string;
  action: Function | string;
}

interface MenuItemDrawerProps {
  icon: string;
  description: string;
  action?: Function | string;
  subMenu?: SubMenuProps[]
}

const ListItem: any = styled(MuiListItem)`${listItem}`;
const MenuItem: any = styled(MuiMenuItem)`${menuItem}`;

const MenuItemDrawer: React.FunctionComponent<MenuItemDrawerProps> = ({
  icon,
  description,
  subMenu,
  action,
}) => {
  const theme = useTheme();
  const openDrawer = useSelector((state) => state.dashboard.drawer.open);
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(-1);
  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' });
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    const actions = subMenu ? subMenu.map((i) => i.action) : [];
    if (pathname === action) {
      setActive(1);
    } else if (actions.includes(pathname)) {
      setOpen(true);
      setActive(actions.indexOf(pathname));
    } else {
      setActive(-1);
    }
  }, [pathname, action, subMenu]);

  const handleClick = () => {
    if (action) {
      if (typeof action === 'function') {
        action();
      } else {
        history.push(action);
      }
    } else if (openDrawer) {
      setOpen(!open);
    }
  };

  const handleClickSub = (menu: any) => {
    if (typeof menu.action === 'function') {
      menu.action();
    } else {
      history.push(menu.action);
    }
  };

  const iconSubMenu = () => {
    if (subMenu && openDrawer && subMenu.length > 0) {
      return open ? <ExpandLess fontSize='small' /> : <ExpandMore fontSize='small' />;
    }
    return null;
  };

  const renderSubMenu = () => {
    if (!openDrawer && !mobile && subMenu && subMenu.length > 0) {
      return (
        <Menu
          {...bindMenu(popupState)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          {
            subMenu.map((i: SubMenuProps, index) => (
              <MenuItem
                key={i.description}
                active={String(active === index)}
                onClick={() => {
                  popupState.close();
                  if (typeof i.action === 'function') {
                    i.action();
                  } else {
                    history.push(i.action);
                  }
                }}>{i.description}</MenuItem>
            ))
          }
        </Menu>
      );
    }
    if (subMenu && subMenu.length > 0) {
      return (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {
              subMenu.map((i, index) => (
                <ListItem
                  key={i.description}
                  active={String(active === index)}
                  theme={theme}
                  onClick={() => handleClickSub(i)}
                  className='sub-menu'
                  button>
                  <ListItemText
                    primary={i.description} />
                </ListItem>
              ))
            }
          </List>
        </Collapse>
      );
    }
    return null;
  };

  return (
    <>
      <ListItem
        button
        {...bindHover(popupState)}
        active={String(
          (active === 1 && !subMenu)
          || (active > -1 && !openDrawer)
          || (active > -1 && !open),
        )}
        theme={theme}
        onClick={handleClick}>
        <ListItemIcon className='icon'>
          <Icon fontSize='small'>{icon}</Icon>
        </ListItemIcon>
        <ListItemText primary={description} />
        {iconSubMenu()}
      </ListItem>
      {renderSubMenu()}
    </>
  );
};

export { MenuItemDrawer };
