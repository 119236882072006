import { useSelector as Selector, useDispatch, TypedUseSelectorHook } from 'react-redux';
import { SomeState } from './reducersProtocols';

export * from './api';

const useSelector: TypedUseSelectorHook<SomeState> = Selector;

export {
  useDispatch,
  useSelector,
};
