import { css } from '@emotion/core';

const root = css`
    max-width: 710px;
    margin: auto;
    padding: 80px 0;

    text-align: center;
    
    > h5 {
        margin-bottom: 32px;
    }
`;

export { root };
