import { MUIDataTableColumn } from 'mui-datatables';

const textLabels = {
  body: {
    noMatch: 'Nenhum registro correspondente encontrado, utilize o ícone + ao lado para criar um novo conteúdo!',
    toolTip: 'Ordenar',
    columnHeaderTooltip: (column: MUIDataTableColumn) => `Ordenar para ${column.label}`,
  },
  pagination: {
    next: 'Próxima Página',
    previous: 'Página Anterior',
    rowsPerPage: 'Linhas por Página:',
    displayRows: 'de',
  },
  toolbar: {
    search: 'Buscar',
    downloadCsv: 'Download CSV',
    print: 'Imprimir',
    viewColumns: 'Exibir colunas',
    filterTable: 'Tabela de filtro',
  },
  filter: {
    all: 'Todos',
    title: 'Filtros',
    reset: 'Redefinir',
  },
  viewColumns: {
    title: 'Mostrar colunas',
    titleAria: 'Mostrar/Ocultar colunas da tabela',
  },
  selectedRows: {
    text: 'Linhas Selecionadas',
    delete: 'Remover',
    deleteAria: 'Remover linhas selecionadas',
  },
};

export { textLabels };
