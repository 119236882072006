import React from 'react';

import AddIcon from '@material-ui/icons/Add';
import { Fab } from '@material-ui/core';
import styled from '@emotion/styled';


import { root } from '~/components/FabAdd/fabAdd.style';

const Root = styled(Fab)`${root}`;

interface FabAddProps {
  onClick: () => void;
}

const FabAdd: React.FunctionComponent<FabAddProps> = ({
  onClick,
}) => (<Root
  color="primary"
  onClick={onClick}
  aria-label="add">
<AddIcon />
</Root>);

export { FabAdd };
