import { css } from '@emotion/core';

const content = css`
  margin: auto;
  width: 350px;
  
  > div:nth-of-type(1),
  > div:nth-of-type(2) {
      min-height: 68px;
      width: 100%;
  }
  
  button {
    margin-bottom: 8px;
  }
`;

export { content };
