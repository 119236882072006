import React from 'react';

import { Paper as MuiPaper, PaperProps } from '@material-ui/core';

import styled from '@emotion/styled';

import { root } from './paper.style';

const Root = styled(MuiPaper)`${root}`;

const Paper: React.FunctionComponent<PaperProps> = ({
  ...others
}) => (
    <Root
        {...others}
    />
);

export { Paper };
