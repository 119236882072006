
import { Dispatch, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { api } from '../api';

export const Types = {
  LIST_EVENTS: 'events/LIST',
  SHOW_FORM_EVENTS: 'events/SHOW_FORM',
  CREATE_EVENTS: 'events/CREATE',
  UPDATE_EVENTS: 'events/UPDATE',
  REMOVE_EVENTS: 'events/REMOVE',
};


interface EventsListAction {
  type: typeof Types.LIST_EVENTS,
  payload: any
}

interface EventsCreateAction {
  type: typeof Types.CREATE_EVENTS,
  payload: any
}

interface EventsUpdateAction {
  type: typeof Types.UPDATE_EVENTS,
  payload: any
}

interface EventsRemoveAction {
  type: typeof Types.REMOVE_EVENTS,
  payload: any
}

export interface EventState {
  pagination: {
    docs: any[];
    totalDocs: number;
    page: number;
    totalPages: number;
    hasNextpage: boolean;
    nextPage: number | null;
    hasPrevPage: boolean;
    prevPage: number | null;
    pagingCounter: number;
  };
  params: object;
  showForm: boolean;
  editData: null | object;
}

const initialState: EventState = {
  pagination: {
    docs: [],
    totalDocs: 0,
    page: 1,
    totalPages: 1,
    hasNextpage: false,
    nextPage: null,
    hasPrevPage: false,
    prevPage: null,
    pagingCounter: 1,
  },
  params: {},
  showForm: false,
  editData: null,
};


export default function reducer(
  state = initialState,
  { type, payload }:
  any,
) {
  switch (type) {
    case Types.LIST_EVENTS:
      return { ...state, pagination: payload.data, params: payload.params };
    case Types.SHOW_FORM_EVENTS:
      return { ...state, showForm: payload.show, editData: payload.data };
    default:
      return state;
  }
}

export const listEvents: ActionCreator<
ThunkAction<
Promise<EventsListAction>,
{},
null,
EventsListAction
>> = (params) => async (dispatch: Dispatch): Promise<EventsListAction> => {
  const data = await api.get('/events', {
    params,
  });
  return dispatch({
    payload: {
      data,
      params,
    },
    type: Types.LIST_EVENTS,
  });
};

export const createEvents: ActionCreator<
ThunkAction<
Promise<EventsCreateAction>,
{},
null,
EventsCreateAction
>> = (values: any) => async (dispatch: Dispatch): Promise<EventsCreateAction> => {
  const result = await api.post('/events', values);
  return dispatch({
    payload: result,
    type: Types.CREATE_EVENTS,
  });
};

export const updateEvents: ActionCreator<
ThunkAction<
Promise<EventsUpdateAction>,
{},
null,
EventsUpdateAction
>> = (values: any) => async (dispatch: Dispatch): Promise<EventsUpdateAction> => {
  const result = await api.put(`/events/${values.id}`, values);
  return dispatch({
    payload: result,
    type: Types.UPDATE_EVENTS,
  });
};

export const removeEvents: ActionCreator<
ThunkAction<
Promise<EventsRemoveAction>,
{},
null,
EventsRemoveAction
>> = (id: string) => async (dispatch: Dispatch): Promise<EventsRemoveAction> => {
  await api.delete(`/events/${id}`);
  return dispatch({
    payload: id,
    type: Types.REMOVE_EVENTS,
  });
};

export const showFormEvents = (show: boolean, data: any = null) => (dispatch: any) => {
  dispatch({ type: Types.SHOW_FORM_EVENTS, payload: { show, data } });
};
