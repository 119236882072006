import React from 'react';

import {
  Grid, Typography,
} from '@material-ui/core';

import styled from '@emotion/styled';

import { Logo, Paper, ButtonRoute } from '~/components';

import { root } from './login.style';
import { Form } from './components';

const Root = styled(Paper)`${root}`;


const Login: React.FunctionComponent = () => (
  <Root elevation={4}>
    <Grid
      container
      direction='column'
      justify='center'
      alignItems='center'
    >
      <Logo />
      <Typography color='primary' variant='h5'>Faça seu login de acesso</Typography>
      <Typography
        color='textSecondary'
      >
        Em poucos passos você poderá gerenciar o site de maneira simples
        e rápida usando a ferramenta administrativa desenvolvida pela Ibaro.
            </Typography>
      <Form />
      <Grid
        container
        justify='center'
      >
        <Grid item>
          <ButtonRoute
            color='secondary'
            to='/forgot-password'>Esqueci minha senha</ButtonRoute>
        </Grid>
      </Grid>
    </Grid>
  </Root>
);

export { Login };
