import { css } from '@emotion/core';

const menu = ({ theme }: any) => css`
  > div {
    li {
      color: ${theme.palette.secondary.text};
    }
    min-width: 200px;
  }

  .MuiSvgIcon-root {
    color: ${theme.palette.secondary.text};
    font-size: 16px;
    margin-right: 8px;
  }
`;

export { menu };
