import React from 'react';

import {
  DialogContent as MuiDialogContent, useTheme,
} from '@material-ui/core';

import styled from '@emotion/styled';


import { dialogContent } from './dialog.styles';

interface DialogContentProps {
  children: React.ReactNode;
}

const Root = styled(MuiDialogContent)`
  ${dialogContent}
`;

const DialogContent: React.FunctionComponent<DialogContentProps> = ({ children }) => {
  const theme = useTheme();

  return (
    <Root
    theme={theme}>
      {children}
    </Root>
  );
};

export { DialogContent };
