export const API_URL = 'https://localhost:7000';

type BasePriceTypes = {
  embargo: number
  ordinario: number
  extra: number
  revista: number
  agravo: number
};

export const BASE_PRICE_APPEAL_TYPE: BasePriceTypes = {
  embargo: 19657.02,
  ordinario: 9828.51,
  extra: 19657.02,
  revista: 19657.02,
  agravo: 19657.02,
};

export const USER_KEY = '__ibaro_user_';
