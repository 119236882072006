
import { Dispatch, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { api } from '../api';

export const Types = {
  LIST_USERS: 'users/LIST',
  SHOW_FORM_USERS: 'users/SHOW_FORM',
  CREATE_USERS: 'users/CREATE',
  UPDATE_USERS: 'users/UPDATE',
  REMOVE_USERS: 'users/REMOVE',
  UPDATE_PASSWORD_USERS: 'users/UPDATE_PASSWORD_USERS',
};


interface UsersListAction {
  type: typeof Types.LIST_USERS,
  payload: any
}

interface UsersCreateAction {
  type: typeof Types.CREATE_USERS,
  payload: any
}

interface UsersUpdateAction {
  type: typeof Types.UPDATE_USERS,
  payload: any
}

interface UsersRemoveAction {
  type: typeof Types.REMOVE_USERS,
  payload: any
}

export interface UserState {
  pagination: {
    docs: any[];
    totalDocs: number;
    page: number;
    totalPages: number;
    hasNextpage: boolean;
    nextPage: number | null;
    hasPrevPage: boolean;
    prevPage: number | null;
    pagingCounter: number;
  };
  showForm: boolean;
  editData: null | object;
}

const initialState: UserState = {
  pagination: {
    docs: [],
    totalDocs: 0,
    page: 1,
    totalPages: 1,
    hasNextpage: false,
    nextPage: null,
    hasPrevPage: false,
    prevPage: null,
    pagingCounter: 1,
  },
  showForm: false,
  editData: null,
};


export default function reducer(
  state = initialState,
  { type, payload }:
  any,
) {
  switch (type) {
    case Types.LIST_USERS:
      return { ...state, pagination: payload };
    case Types.SHOW_FORM_USERS:
      return { ...state, showForm: payload.show, editData: payload.data };
    case Types.CREATE_USERS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          docs: [...state.pagination.docs, payload],
        },
      };
    case Types.UPDATE_USERS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          docs: [...state.pagination.docs.map((i: any) => {
            if (i.id !== payload.id) return i;
            return payload;
          })],
        },
      };
    case Types.REMOVE_USERS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          docs: [...state.pagination.docs.filter((i:any) => i.id !== payload)],
        },
      };
    default:
      return state;
  }
}

interface Pagination {
  search?: string;
  page: number;
  sort: string;
  limit: number;
}

export const listUsers: ActionCreator<
ThunkAction<
Promise<UsersListAction>,
{},
null,
UsersListAction
>> = (params: Pagination) => async (dispatch: Dispatch): Promise<UsersListAction> => {
  const data = await api.get('/users', {
    params,
  });
  return dispatch({
    payload: data,
    type: Types.LIST_USERS,
  });
};

export const createUsers: ActionCreator<
ThunkAction<
Promise<UsersCreateAction>,
{},
null,
UsersCreateAction
>> = (values: any) => async (dispatch: Dispatch): Promise<UsersCreateAction> => {
  const data = { ...values };
  const result = await api.post('/users', data);
  return dispatch({
    payload: result,
    type: Types.CREATE_USERS,
  });
};

export const updateUsers: ActionCreator<
ThunkAction<
Promise<UsersUpdateAction>,
{},
null,
UsersUpdateAction
>> = (values: any) => async (dispatch: Dispatch): Promise<UsersUpdateAction> => {
  const data = { ...values };
  const result = await api.put(`/users/${values.id}`, data);
  return dispatch({
    payload: result,
    type: Types.UPDATE_USERS,
  });
};

export const updatePasswordUsers: ActionCreator<
ThunkAction<
Promise<UsersUpdateAction>,
{},
null,
UsersUpdateAction
>> = (values: any) => async (dispatch: Dispatch): Promise<UsersUpdateAction> => {
  const data = { ...values };
  const result = await api.put(`/users/${values.id}/password`, data);
  return dispatch({
    payload: result,
    type: Types.UPDATE_PASSWORD_USERS,
  });
};

export const removeUsers: ActionCreator<
ThunkAction<
Promise<UsersRemoveAction>,
{},
null,
UsersRemoveAction
>> = (id: string) => async (dispatch: Dispatch): Promise<UsersRemoveAction> => {
  await api.delete(`/users/${id}`);
  return dispatch({
    payload: id,
    type: Types.REMOVE_USERS,
  });
};

export const showFormUsers = (show: boolean, data: any = null) => (dispatch: any) => {
  dispatch({ type: Types.SHOW_FORM_USERS, payload: { show, data } });
};
