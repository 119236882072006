import { css } from '@emotion/core';


const root = css`
  *, *:before, *:after { box-sizing: border-box; }
  
  td {
    min-height: 62px;
    height: unset;
    line-height: 30px;
  }
`;

interface ActionCell {
  width: string;
}

const actionCell = ({ width }: ActionCell) => css`
  width: ${width};
`;

export { root, actionCell };
