import React from 'react';

import {
  AppBar as Root, IconButton, Toolbar, useMediaQuery,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';

import { useTheme } from '@material-ui/core/styles';

import styled from '@emotion/styled';

import { useDispatch } from '~/store';
import { toogleDrawer, openDrawer } from '~/store/dashboard';
import { logout } from '~/store/auth';

import { Logo } from '~/components';

import { root, contentNav } from './appBar.style';

const AppBarRoot: any = styled(Root)`${root}`;
const ContentNav = styled.div`${contentNav}`;

interface AppBarProps {
  className: string | undefined;
}

const AppBar: React.FunctionComponent<AppBarProps> = ({
  className,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = () => {
    if (mobile) dispatch(openDrawer());
    else dispatch(toogleDrawer());
  };

  const handleLogout = () => {
    dispatch(logout());
  };


  return (
    <AppBarRoot
      className={className}
      position="fixed"
      mobile={mobile.toString()}
    >
      <Toolbar>
        <ContentNav>
          <IconButton
            className='toogle-drawer'
            aria-label="open drawer"
            onClick={handleClick}
            edge="start"
          >
            <MenuIcon className='toogle-drawer' fontSize='small' />
          </IconButton>
          {mobile && (<Logo
            width={160}
            height={48}
          />)}
        </ContentNav>
        <IconButton
          aria-label="Logout"
          onClick={handleLogout}
          edge="start"
        >
          <ExitIcon fontSize='small' />
        </IconButton>
      </Toolbar>
    </AppBarRoot >
  );
};


export { AppBar };
