import React, { useEffect } from 'react';

import {
  Step, Stepper, StepButton, Typography,
} from '@material-ui/core';

import { Formik } from 'formik';

import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import { useDispatch, useSelector } from '~/store';
import {
  showFormNews, createNews, updateNews, listNews,
} from '~/store/news';

import {
  Dialog, DialogTitle, DialogContent, ListInfosForm,
} from '~/components';

import { ContentForm, validationSchema } from './components';
import { showAlert } from '~/store/alert';

interface newsFormProps {
  open: boolean;
}

const NewsForm: React.FunctionComponent<newsFormProps> = ({
  open,
}) => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const { editData, params } = useSelector((state) => state.news);
  let initialValues: any = {
    postedAt: '',
    hashtags: [],
    attachment: '',
    attachmentLarge: '',
    attachmentSmall: '',
    portuguese: {
      title: '',
      source: '',
      text: '',
    },
    english: {
      title: '',
      source: '',
      text: '',
    },
  };

  if (editData) {
    const english = editData?.english?.title
      ? { ...editData.english }
      : { ...initialValues.english };
    const portuguese = editData?.portuguese?.title
      ? { ...editData.portuguese }
      : { ...initialValues.portuguese };
    initialValues = {
      ...initialValues,
      ...editData,
      english,
      portuguese,
    };
  }

  useEffect(() => {
    setActiveStep(0);
  }, [open]);

  const handleStep = (newActiveStep: number) => {
    setActiveStep(newActiveStep);
  };

  const handleClose = () => {
    dispatch(showFormNews(false));
  };

  const handleSubmit = async (values: any) => {
    try {
      const data = JSON.parse(JSON.stringify(values));
      let { attachmentLarge } = values;
      let { attachmentSmall } = values;
      attachmentLarge = new File(
        [attachmentLarge],
        attachmentLarge.name,
        { type: attachmentLarge.type },
      );
      attachmentSmall = new File(
        [attachmentSmall],
        attachmentSmall.name,
        { type: attachmentSmall.type },
      );
      data.attachmentLarge = attachmentLarge;
      data.attachmentSmall = attachmentSmall;
      data.hashtags = data.hashtags.map((i: any) => i.id);
      if (data.portuguese.text) {
        data.portuguese.text = draftToHtml(
          convertToRaw(values.portuguese.text.getCurrentContent()),
        );
      }
      if (data.english.text) {
        data.english.text = draftToHtml(
          convertToRaw(values.english.text.getCurrentContent()),
        );
      }
      await dispatch(data.id ? updateNews(data) : createNews(data));
      dispatch(listNews(params));
      dispatch(showAlert({
        message: 'Notícia salvo com sucesso',
      }));
      handleClose();
    } catch (_) {
      dispatch(showAlert({
        message: 'Falha ao realizar a operação',
        type: 'error',
      }));
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth={false}
    >
      <DialogTitle
        title='Cadastrar uma notícia'
        onClose={handleClose}
      />
      <DialogContent>
        <ListInfosForm>
          <li>
            <Typography>
              Utilize os formulários das etapas abaixo para inserir, primeiro a imagem da notícia,
              depois ajuste-a no formato oficial do site e em seguida coloque a versão em português
              e depois a versão em inglês.
            </Typography>
          </li>
          <li>
            <Typography>
              ATENÇÃO: Caso deixe uma das versões sem conteúdo, a mesma não
              aparecerá no front daquela versão.
              Ex: Se a notícia está apenas em português, basta deixar a
              etapa “Inglês” vazia que essa
              notícia aparecerá apenas na versão em português e vice-versa.
            </Typography>
          </li>
        </ListInfosForm>
        <Stepper
          nonLinear={!!initialValues.id}
          alternativeLabel
          activeStep={activeStep}>
          <Step>
            <StepButton onClick={() => { handleStep(0); }}>Imagem da notícia</StepButton>
          </Step>
          <Step>
            <StepButton onClick={() => { handleStep(1); }}>Ajustar Imagem</StepButton>
          </Step>
          <Step>
            <StepButton onClick={() => { handleStep(2); }}>Texto em português</StepButton>
          </Step>
          <Step>
            <StepButton onClick={() => { handleStep(3); }}>Texto em inglês</StepButton>
          </Step>
        </Stepper>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({
            isSubmitting,
            setFieldValue,
            values,
            validateForm,
            setFieldTouched,
          }) => (
            <ContentForm
              values={values}
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              validateForm={validateForm}
              setFieldTouched={setFieldTouched}
            />
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export { NewsForm };
