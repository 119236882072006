import React from 'react';

import { Grid } from '@material-ui/core';

import { FabAdd, Table } from '~/components';
import { useDispatch, useSelector } from '~/store';
import { listCompliances, removeCompliances, showFormCompliances } from '~/store/compliance';

import { CompliancesForm } from './CompliancesForm';
import { showConfirmation } from '~/store/confirm';

const CompliancesList: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { pagination, showForm, params } = useSelector((state) => state.compliance);

  const handleAdd = () => {
    dispatch(showFormCompliances(true));
  };

  const handleEdit = (rowData: any) => {
    const data = JSON.parse(JSON.stringify(rowData));
    if (data.english && data.english.attachment) {
      data.english.attachment = {
        s3: true,
        url: data.english.attachment,
        name: 'Arquivo enviado anteriormente...',
      };
    }
    if (data.portuguese && data.portuguese.attachment) {
      data.portuguese.attachment = {
        s3: true,
        url: data.portuguese.attachment,
        name: 'Arquivo enviado anteriormente...',
      };
    }
    dispatch(showFormCompliances(true, data));
  };

  const handleRemove = (id: string) => {
    dispatch(showConfirmation({
      message: 'Você tem certeza que deseja deletar esse item?',
      proceed: async () => {
        await dispatch(removeCompliances(id));
        dispatch(listCompliances(params));
      },
    }));
  };

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      <CompliancesForm
        open={showForm} />
        <Table
            title={'Compliance'}
            data={pagination.docs}
            pagination={pagination}
            initialFilters={{
              sort: 'portuguese.description',
            }}
            serverAction={listCompliances}
            columns={[
              { label: 'Documento (Em português)', name: 'portuguese.description' },
              { label: 'Documento (Em inglês)', name: 'english.description' },
            ]}
            actions={[
              {
                icon: 'add',
                tooltip: 'Adicionar Compliances',
                isFreeAction: true,
                onClick: handleAdd,
              },
              {
                icon: 'create',
                tooltip: 'Editar Compliances',
                onClick: (rowData: any) => {
                  handleEdit(rowData);
                },
              },
              {
                icon: 'delete',
                tooltip: 'Remover Compliances',
                onClick: (rowData: any) => {
                  handleRemove(rowData.id);
                },
              },
            ]}
          />
      <FabAdd onClick={handleAdd} />
    </Grid>
  );
};

export { CompliancesList };
