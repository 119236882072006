import React from 'react';

export const Types = {
  SHOW_CONFIRM: 'confirm/SHOW',
  CLOSE_CONFIRM: 'confirm/CLOSE',
};

export interface ConfirmState {
  show: boolean;
  proceed: () => void;
  message: string | React.ReactNode;
  dismiss: () => void;
}

const initialState: ConfirmState = {
  show: false,
  proceed: () => {},
  message: '',
  dismiss: () => {},
};


export default function reducer(
  state = initialState,
  { type, payload }:
  any,
) {
  switch (type) {
    case Types.SHOW_CONFIRM:
      return {
        ...state,
        show: true,
        ...payload,
      };
    case Types.CLOSE_CONFIRM:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

interface ShowConfirmationProps {
  proceed: () => void;
  message: string | React.ReactNode;
  dismiss?: () => void;
}

export const closeConfirmation = () => (dispatch: any) => {
  dispatch({ type: Types.CLOSE_CONFIRM, payload: null });
};

export const showConfirmation = ({
  proceed,
  message,
  dismiss,
}: ShowConfirmationProps) => (dispatch: any) => {
  dispatch({
    type: Types.SHOW_CONFIRM,
    payload: {
      proceed,
      message,
      dismiss: () => {
        if (dismiss) dismiss();
        dispatch(closeConfirmation());
      },
    },
  });
};
