import React from 'react';
import {
  Route, Redirect, Switch,
} from 'react-router-dom';

import Helmet from 'react-helmet';

import { Dashboard } from '~/containers';
import {
  api, useSelector,
} from '~/store';
import { Alert, Confirm } from '~/components';

import { Home } from './Home';
import { Login } from './Login';
import { ForgotPassword } from './ForgotPassword';
import { ResetPassword } from './ResetPassword';
import { Compliances } from './Compliances';
import { Opportunities } from './Opportunities';
import { News } from './News';
import { Events } from './Events';
import { Funds } from './Funds';
import { Hashtags } from './Hashtags';
import { Users } from './Users';
import { ForceChangePassword } from './ForceChangePassword';
import { MyProfile } from './MyProfile';


export const Pages: React.FunctionComponent = () => {
  const auth = useSelector((state) => state.auth);
  const { role } = useSelector((state) => state.profile.current);

  const { user } = auth;
  if (user) {
    const bodyStyle: any = { style: 'height: 100%; margin: 0;' };
    api.defaults.headers.common.authorization = `Bearer ${user.accessToken}`;

    if (user.changePassword) {
      return (
        <>
          <Helmet bodyAttributes={bodyStyle} />
          <Alert />
          <Confirm />
          <Switch>
              <Route exact path="/change-password" component={ForceChangePassword} />
              <Redirect from="*" to="/change-password" />
          </Switch>
        </>
      );
    }

    return (
      <>
        <Helmet bodyAttributes={bodyStyle} />
        <Alert />
        <Confirm />
        <Switch>
          <Dashboard>
            <Route exact path="/home" component={Home} />
            <Route exact path="/profile" component={MyProfile} />
            <Route exact path="/compliances" component={Compliances} />
            <Route exact path="/news" component={News} />
            <Route exact path="/funds/:type" component={Funds} />
            <Route exact path="/events" component={Events} />
            <Route exact path="/opportunities" component={Opportunities} />
            <Route exact path="/hashtags" component={Hashtags} />
            {role === 'SUPER_ADMIN' && (<Route exact path='/users' component={Users} />)}
          </Dashboard>
          <Redirect from="*" to="/home" />
        </Switch>
      </>
    );
  }
  if (!user) {
    return (
      <>
        <Alert />
        <Confirm />
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path='/forgot-password' component={ForgotPassword} />
          <Route exact path='/reset-password/:token' component={ResetPassword} />
          <Redirect from="*" to="/" />
        </Switch>
      </>
    );
  } return null;
};
