import React from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { Button, DialogTitle } from '~/components';
import { useDispatch, useSelector } from '~/store';
import { closeConfirmation } from '~/store/confirm';


const Confirm: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const {
    show, dismiss, proceed, message,
  } = useSelector((state) => state.confirm);
  return (
    <Dialog
      open={show}
    >
      <DialogTitle
        title='Confirmar ação'
        onClose={dismiss}
      />
      <DialogContent>
        {
          typeof message === 'string'
            ? (<DialogContentText>{message}</DialogContentText>)
            : message
        }
      </DialogContent>
      <DialogActions>
        { proceed && (
          <Button onClick={() => {
            proceed();
            dispatch(closeConfirmation());
          }} color="primary" autoFocus>
            Confirmar
          </Button>
        )}
        <Button onClick={dismiss} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { Confirm };
