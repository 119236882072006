import { css } from '@emotion/core';

const textField = css`
    input, .MuiInputBase-root {
        background-color: #eee;
        border-radius: 12px;
    }

    label {
        border-color: #b7cade;
    }

    .MuiInputBase-root  {
        border-radius: 10px;
    }

    .MuiFormLabel-root.Mui-focused {
      color: #454f5b !important;
    }
`;

const checkboxIcon = css`
        margin: 0 2px;
        border-radius: 2px;
        border: 1px solid #b7cade;
        background-color: #eee;
        height: 16px;
        width: 16px;
        `;

const checkboxCheckedIcon = ({ theme }: any) => css`
    background-color: ${theme.palette.primary.main};

    :before {
        display: block;
        width: 16px;
        height: 16px;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E");
        content: "";
    }
`;

const labelCheckbox = css`
    color: #a7a7a7;
    margin-left: 8px;
`;

const tooltipInfo = ({ theme }: any) => css`
    color: ${theme.palette.grey[600]};
    margin-right: 12px;
`;

const tooltipTitle = css`
  font-size: 14px;
`;


export {
  textField, checkboxIcon, checkboxCheckedIcon, labelCheckbox, tooltipInfo, tooltipTitle,
};
