import { css } from '@emotion/core';

const root = css`
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;

    > div {
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        border-radius: 12px;
    }
`;

export { root };
