
import { Dispatch, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { api } from '../api';

export const Types = {
  GET_PROFILE: 'profile/GET_PROFILE',
  UPDATE_PROFILE: 'profile/UPDATE_PROFILE',
  UPDATE_PASSWORD: 'profile/UPDATE_PASSWORD',
};


interface ProfileUpdatePasswordAction {
  type: typeof Types.UPDATE_PASSWORD,
  payload: any
}

interface GetProfileAction {
  type: typeof Types.GET_PROFILE,
  payload: any
}

interface UpdateProfileAction {
  type: typeof Types.UPDATE_PROFILE,
  payload: any
}

export interface ProfileState {
  current: {
    id: string;
    name: string;
    email: string;
    role: string;
  };
}

const initialState: ProfileState = {
  current: {
    id: '',
    name: '',
    email: '',
    role: 'ADMIN',
  },
};


export default function reducer(
  state = initialState,
  { type, payload }:
  any,
) {
  switch (type) {
    case Types.GET_PROFILE:
    case Types.UPDATE_PROFILE:
      return { ...state, current: payload };
    default:
      return state;
  }
}


export const getProfile: ActionCreator<
ThunkAction<
Promise<GetProfileAction>,
{},
null,
GetProfileAction
>> = () => async (dispatch: Dispatch): Promise<GetProfileAction> => {
  const result = await api.get('/profile');
  return dispatch({
    payload: result,
    type: Types.GET_PROFILE,
  });
};

export const updatePasswordProfile: ActionCreator<
ThunkAction<
Promise<ProfileUpdatePasswordAction>,
{},
null,
ProfileUpdatePasswordAction
>> = (values: any) => async (dispatch: Dispatch): Promise<ProfileUpdatePasswordAction> => {
  const data = { ...values };
  const result = await api.put('/profile/password', data);
  return dispatch({
    payload: result,
    type: Types.UPDATE_PASSWORD,
  });
};

export const updateProfile: ActionCreator<
ThunkAction<
Promise<UpdateProfileAction>,
{},
null,
UpdateProfileAction
>> = (values: any) => async (dispatch: Dispatch): Promise<UpdateProfileAction> => {
  const data = { ...values };
  const result = await api.put('/profile', data);
  return dispatch({
    payload: result,
    type: Types.UPDATE_PROFILE,
  });
};
