import React, { useState } from 'react';

import {
  IconButton, Tooltip,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import styled from '@emotion/styled';

import { useTheme } from '@material-ui/styles';
import { tooltipInfo, tooltipTitle } from './form.styles';
import { DialogInfo } from './components';


const TooltipInfo = styled(Tooltip)`${tooltipInfo}`;
const TooltipTitle = styled.span`${tooltipTitle}`;


interface HelperEndAndormentProps {
  helper: string
  helperDialog?: boolean
  className?: string
}

const HelperEndAndorment: React.FunctionComponent<HelperEndAndormentProps> = ({
  helper,
  helperDialog,
  className,
}) => {
  const theme = useTheme();

  const [showDialog, setShowDialog] = useState(false);
  if (!helperDialog) {
    return (
      <TooltipInfo
        className={className}
        theme={theme}
        title={(<TooltipTitle className='title'>{helper}</TooltipTitle>)}>
        <InfoIcon />
      </TooltipInfo>
    );
  }
  return (
    <>
      <Tooltip
        className={className}
        title={(<TooltipTitle className='title'>Clique para visualizar</TooltipTitle>)}>
        <IconButton onClick={() => setShowDialog(true)}>
          <InfoIcon />
        </IconButton>
      </Tooltip>
      <DialogInfo
        open={showDialog}
        handleClose={() => setShowDialog(false)}
        helper={helper}
      />
    </>
  );
};

export { HelperEndAndorment };
