import React from 'react';

import { InputFile as Root, InputFileProps } from '@startage/react-ui-tools';

const InputFile: React.FunctionComponent<InputFileProps> = ({
  ...opts
}) => (
  <Root
    textDrag={(
      <span>
        Solte o arquivo aqui ou
        <br/>
        clique para procurar o arquivo e escolher em seguida
      </span>
    )}
    {...opts}
    />
);

export { InputFile };
