import { css } from '@emotion/core';
import { drawerWidthMin, drawerWidth } from '~/constants/style';

const root = ({
  mobile,
  theme,
  openDrawer,
}:any) => css`
    display: flex;
    height: 100%;
    overflow: auto;
    background-color: #fafafa;

    .MuiDrawer-root > div {
      width: ${drawerWidth}px;
    }

    .drawer-open > div {
      overflow-x: hidden;
      transition: ${theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  })};
    }

    .drawer-close > div {
      transition: ${theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  })};
      overflow-x: hidden;
      width: ${drawerWidthMin}px;

      .MuiListItemText-root, .hide-close {
        display: none;
      }

      .main-menu {
        height: calc(100% - 158px);
      }

      .avatar {
        height: 24px;
        width: 24px;
      }
    }

    .appbar-open {
      transition: ${theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  })};
      width: calc(100% - ${drawerWidth}px);
      margin-left: ${drawerWidth}px;
      ${mobile && 'margin-left: 0;'}
      ${mobile && 'width: 100%;'}
    }

    .appbar-close {
      transition: ${theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  })};
      width: calc(100% - ${drawerWidthMin}px);
      margin-left: ${drawerWidthMin}px;
      ${mobile && 'margin-left: 0;'}
      ${mobile && 'width: 100%;'}
    }

    main {
      margin-top: 72px;
      margin-left: ${!openDrawer ? drawerWidthMin : drawerWidth}px;
      padding: 16px 16px 16px 16px;
      width: 100%;
      ${mobile && 'margin-left: 0;'}
    }
`;

export { root };
