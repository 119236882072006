import React from 'react';

import {
  Grid, Typography,
} from '@material-ui/core';

import styled from '@emotion/styled';

import { Logo, Paper, ButtonRoute } from '~/components';

import { root } from './resetPassword.style';
import { Form } from './components';

const Root = styled(Paper)`${root}`;


const ResetPassword: React.FunctionComponent = () => (
    <Root>
        <Grid
            container
            direction='column'
            justify='center'
            alignItems='center'
        >
            <Logo />
            <Typography color='primary' variant='h6'>Redefinir senha</Typography>
            <Form />
            <Grid
                container
                justify='center'
            >
                <Grid item>
                    <ButtonRoute
                    color='primary'
                    to='/'>Login</ButtonRoute>
                </Grid>
            </Grid>
        </Grid>
    </Root>
);

export { ResetPassword };
