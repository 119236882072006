import React from 'react';

import {
  Grid,
} from '@material-ui/core';

import { Formik, Form as FormFormik } from 'formik';

import { useHistory } from 'react-router-dom';

import { Button, TextField } from '~/components';
import { useDispatch } from '~/store';
import { showAlert } from '~/store/alert';
import { forgotPassword } from '~/store/auth';

import { validationSchema } from './schema';

const Form: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = async (values: any) => {
    await dispatch(forgotPassword(values));
    dispatch(showAlert({
      message: `Enviamos para o seu email o 
        processo de redefinição de senha`,
    }));
    history.push('/');
  };

  return (
        <Formik
            initialValues={{ email: '' }}
            onSubmit={handleSubmit}
            enableReinitialize
            validationSchema={validationSchema}
        >
            {({ isSubmitting }) => (
                <FormFormik>
                    <Grid
                        container
                        spacing={1}
                        direction='row'>
                        <Grid item>
                            <TextField
                                label='E-mail'
                                name='email'
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                type='submit'
                                color='primary'
                                fullWidth
                                large
                                variant='contained'
                                isSubmitting={isSubmitting}
                            >
                                Redefinir senha
                        </Button>
                        </Grid>
                    </Grid>
                </FormFormik>
            )}
        </Formik>
  );
};

export { Form };
