import React from 'react';

import Helmet from 'react-helmet';

import styled from '@emotion/styled';

import { Container, Grid } from '@material-ui/core';

import { ForceChangePassword as ForceChangePasswordContainer } from '~/containers';

import { root } from './forceChangePassword.style';

const Root = styled(Container)`${root}`;

const ForceChangePassword: React.FunctionComponent = () => {
  const bodyStyle: any = { style: 'background-color: #eee; margin: 0; height: 100%;' };
  return (
        <Root>
            <Helmet bodyAttributes={bodyStyle}/>
            <Grid
            container
            justify='center'
            alignItems='center'
            >
                <ForceChangePasswordContainer />
            </Grid>
        </Root>
  );
};

export { ForceChangePassword };
