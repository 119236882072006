import React from 'react';

import {
  Dialog as MuiDialog, DialogProps, Theme, useTheme,
} from '@material-ui/core';
import styled from '@emotion/styled';

import { dialog } from './dialog.styles';

interface RootProps {
  theme: Theme;
}

const Root = styled(MuiDialog)<RootProps>`${dialog}`;

const Dialog: React.FunctionComponent<DialogProps> = ({
  children,
  ...others
}) => {
  const theme = useTheme();

  return (
    <Root
      theme={theme}
      {...others}
    >
      { children }
    </Root>
  );
};

export { Dialog };
