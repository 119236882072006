import React, { useEffect } from 'react';

import {
  Step, Stepper, StepButton, Typography,
} from '@material-ui/core';

import { Formik } from 'formik';

import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import { useDispatch, useSelector } from '~/store';
import {
  showFormEvents, createEvents, updateEvents, listEvents,
} from '~/store/event';

import {
  Dialog, DialogTitle, DialogContent, ListInfosForm,
} from '~/components';

import { ContentForm, validationSchema } from './components';
import { showAlert } from '~/store/alert';

interface EventsFormProps {
  open: boolean;
}

const EventsForm: React.FunctionComponent<EventsFormProps> = ({
  open,
}) => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const { editData, params } = useSelector((state) => state.event);

  useEffect(() => {
    setActiveStep(0);
  }, [open]);

  const handleStep = (newActiveStep: number) => {
    setActiveStep(newActiveStep);
  };

  const handleClose = () => {
    dispatch(showFormEvents(false));
  };

  const handleSubmit = async (values: any) => {
    try {
      const data = JSON.parse(JSON.stringify(values));
      if (data.portuguese.text) {
        data.portuguese.text = draftToHtml(
          convertToRaw(values.portuguese.text.getCurrentContent()),
        );
      }
      if (data.english.text) {
        data.english.text = draftToHtml(
          convertToRaw(values.english.text.getCurrentContent()),
        );
      }
      await dispatch(data.id ? updateEvents(data) : createEvents(data));
      dispatch(listEvents(params));
      dispatch(showAlert({
        message: 'Evento salvo com sucesso',
      }));
      handleClose();
    } catch (_) {
      dispatch(showAlert({
        message: 'Falha ao realizar a operação',
        type: 'error',
      }));
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth={false}
    >
      <DialogTitle
        title='Cadastrar um evento'
        onClose={handleClose}
      />
      <DialogContent>
        <ListInfosForm>
          <li>
            <Typography>
              Utilize os formulários das etapas abaixo para inserir,
              primeiro a versão em português e depois a versão em inglês.
            </Typography>
          </li>
          <li>
            <Typography>
              ATENÇÃO: Caso deixe uma das versões sem conteúdo,
              a mesma não aparecerá no front daquela versão.
              Ex: Se o evento está apenas em português, basta deixar
              a etapa “Inglês” vazia que esse evento aparecerá
              apenas na versão em português e vice-versa.”
            </Typography>
          </li>
        </ListInfosForm>
        <Stepper
          nonLinear
          alternativeLabel
          activeStep={activeStep}>
          <Step>
            <StepButton onClick={() => { handleStep(0); }}>Texto em português</StepButton>
          </Step>
          <Step>
            <StepButton onClick={() => { handleStep(1); }}>Texto em inglês</StepButton>
          </Step>
        </Stepper>
        <Formik
          initialValues={editData || {
            scheduledAt: '',
            portuguese: {
              title: '',
              text: '',
              isBlankTarget: true,
              link: '',
            },
            english: {
              title: '',
              text: '',
              isBlankTarget: true,
              link: '',
            },
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        validationSchema={validationSchema}
        >
          {({
            isSubmitting,
            validateForm,
            setFieldTouched,
          }) => (
            <ContentForm
              isSubmitting={isSubmitting}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              validateForm={validateForm}
              setFieldTouched={setFieldTouched}
            />
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export { EventsForm };
