import * as yup from 'yup';

export const validationSchema = () => {
  const schema = {
    portuguese: yup.object().shape({
      description: yup.string().test('dependency', 'Campo obrigatório', function valid(value) {
        const { attachment } = this.parent;
        if (!attachment) return true;
        return value != null;
      }),
      attachment: yup.mixed().test('dependency', 'Campo obrigatório', function valid(value: any) {
        const { description } = this.parent;
        if (!description) return true;
        return value != null;
      }),
    }),
    english: yup.object().shape({
      description: yup.string().test('dependency', 'Campo obrigatório', function valid(value) {
        const { attachment } = this.parent;
        if (!attachment) return true;
        return value != null;
      }),
      attachment: yup.mixed().test('dependency', 'Campo obrigatório', function valid(value: any) {
        const { description } = this.parent;
        if (!description) return true;
        return value != null;
      }),
    }),
  };
  return yup.object().shape(schema);
};
