import * as yup from 'yup';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import { htmlToText } from '~/helpers';

export const validationSchema = () => {
  const schema = {
    postedAt: yup.mixed().required(),
    attachment: yup.mixed().required(),
    portuguese: yup.object().shape({
      title: yup.string().test('dependency', 'Campo obrigatório', function valid(value) {
        const { text } = this.parent;
        if (!text) return true;
        return !!value;
      }),
      text: yup.mixed().test('dependency', 'Campo obrigatório', function valid(value: any) {
        const valueHtml = value ? htmlToText(draftToHtml(
          convertToRaw(value.getCurrentContent()),
        )) : null;
        const { title } = this.parent;
        if (!title) return true;
        return !!valueHtml;
      }),
    }),
    english: yup.object().shape({
      title: yup.string().test('dependency', 'Campo obrigatório', function valid(value) {
        const { text } = this.parent;
        if (!text) return true;
        return value != null;
      }),
      text: yup.mixed().test('dependency', 'Campo obrigatório', function valid(value: any) {
        const valueHtml = value ? htmlToText(draftToHtml(
          convertToRaw(value.getCurrentContent()),
        )) : null;
        const { title } = this.parent;
        if (!title) return true;
        return !!valueHtml;
      }),
    }),
  };
  return yup.object().shape(schema);
};
