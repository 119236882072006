import React from 'react';

import {
  Grid, Typography,
} from '@material-ui/core';

import { Form as FormFormik } from 'formik';

import styled from '@emotion/styled';

import { Button, TextField, InputFile } from '~/components';

import { content } from './contentForm.style';

const Content = styled(Grid)`${content}`;

interface ContentFormProps {
  isSubmitting: boolean;
}

const ContentForm: React.FunctionComponent<ContentFormProps> = ({
  isSubmitting,
}) => (
    <FormFormik>
      <Content
        container
        spacing={2}
        justify="center"
        alignItems="center"
        direction="column"
      >
        <ul>
          <li>
            <Typography>
              A listagem só exibirá o arquivo do último mês
              vigente e um novo mês sobrescreverá o anterior.
            </Typography>
          </li>
          <li>
            <Typography>
              Utilize o formulário abaixo para inserir
              o PDF do Informativo deste fundo para o mês vigente.
            </Typography>
          </li>
          <li>
            <Typography>
              A versão em inglês não possui essa ferramenta e portanto,
              o campo ficará oculto no front em inglês.
            </Typography>
          </li>
        </ul>
        <Grid
          item>
          <TextField
            label='Mês Vigente'
            helper='Favor criar e manter um padrão de nomenclatura para melhor qualidade do site. Ex: Janeiro/2020'
            name='portuguese.description'
            fullWidth
          />
        </Grid>
        <Grid
          item>
          <InputFile
            label='Documento para download'
            name='portuguese.attachment'
            multiple={false}
            />
        </Grid>
        <Grid
          item>
          <Button
            type='submit'
            fullWidth
            large
            color='primary'
            variant='contained'
            isSubmitting={isSubmitting}
          >
            Salvar
          </Button>
        </Grid>
      </Content>
     </FormFormik>
);

export { ContentForm };
