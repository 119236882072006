import React from 'react';

import {
  Grid, Typography,
} from '@material-ui/core';

import { Form as FormFormik } from 'formik';

import styled from '@emotion/styled';
import {
  Button, TextField, Editor, CheckBox,
} from '~/components';

import { step } from './contentForm.style';


const Step: any = styled(Grid)`${step}`;

interface ContentFormProps {
  isSubmitting: boolean;
  activeStep: number;
  setActiveStep: any;
  validateForm: () => Promise<any>;
  setFieldTouched: (field: string) => void;
}

const ContentForm: React.FunctionComponent<ContentFormProps> = ({
  isSubmitting,
  activeStep,
  setActiveStep,
  validateForm,
  setFieldTouched,
}) => (
    <FormFormik>
      <Step
        container
        spacing={1}
        active={String(activeStep === 0)}
        justify="center"
        alignItems="center"
        direction="column"
      >
        <Grid
          item
          sm={12}
          md={12}>
          <TextField
            label='Data do Evento'
            name='scheduledAt'
            type='date'
            helper='Data para as duas linguagens'
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid
        item
        sm={12}
        md={12}>
          <Typography variant='caption'>
            ATENÇÃO: Caso esse evento seja apenas para a versão
            em inglês, apenas coloque a data exata de quando
            será o evento e pule para a próxima etapa.
          </Typography>
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <TextField
            label='Título do evento com até 80 caracteres'
            name='portuguese.title'
            fullWidth
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <Typography variant='caption'>
            Texto do evento com até 500 caracteres: O sistema permite uma certa liberdade
            na diagramação do texto, mas sugerimos cuidado para que essa diagramação não
            fique fora do padrão do site, portanto, sempre limpe a diagramação do texto
            colado usando a ferramenta de apagar (Ícone da borracha).
          </Typography>
        </Grid>
        <Grid
          className='editor'
          item
          sm={12}
          md={12}>
          <Editor
            name='portuguese.text'
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <Typography variant='caption'>
            Caso deseje remeter este evento a uma página específica do evento,
            cole o link dessa página no campo abaixo. Caso o evento não remeta
            para nenhuma página, basta deixar o campo de link vazio.
          </Typography>
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <TextField
            label='Link (PT-br)'
            name='portuguese.link'
            fullWidth
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <Typography variant='caption'>
            Sugerimos que abra em nova janela se o link sobre o
            evento estiver em outro site e não abra em outra janela
            se o link sobre esse evento estiver dentro do próprio
            site da Jive como numa notícia que fala sobre o evento.
          </Typography>
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <CheckBox
            label='Nova Janela'
            name='portuguese.isBlankTarget'
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
            <Button
              fullWidth
              large
              onClick={() => {
                validateForm().then((res) => {
                  setFieldTouched('portuguese.title');
                  setFieldTouched('portuguese.text');
                  if (!res.portuguese) setActiveStep(1);
                });
              }}
              color='primary'
              variant='contained'
            >
              Avançar
          </Button>
        </Grid>
      </Step>
      <Step
        container
        active={String(activeStep === 1)}
        spacing={1}
        direction='row'>
        <Grid
          item
          sm={12}
          md={12}>
          <TextField
            label='Data do Evento'
            name='scheduledAt'
            type='date'
            helper='Data para as duas linguagens'
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <TextField
            label='Título (EN)'
            name='english.title'
            fullWidth
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <Typography variant='caption'>
            Texto do evento com até 500 caracteres: O sistema permite uma certa liberdade
            na diagramação do texto, mas sugerimos cuidado para que essa diagramação não
            fique fora do padrão do site, portanto, sempre limpe a diagramação do texto
            colado usando a ferramenta de apagar (Ícone da borracha).
          </Typography>
        </Grid>
        <Grid
          className='editor'
          item
          sm={12}
          md={12}>
          <Editor
            name='english.text'
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <Typography variant='caption'>
            Caso deseje remeter este evento a uma página específica do evento,
            cole o link dessa página no campo abaixo. Caso o evento não remeta
            para nenhuma página, basta deixar o campo de link vazio.
          </Typography>
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <TextField
            label='Link (EN)'
            name='english.link'
            fullWidth
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <Typography variant='caption'>
            Sugerimos que abra em nova janela se o link sobre o
            evento estiver em outro site e não abra em outra janela
            se o link sobre esse evento estiver dentro do próprio
            site da Jive como numa notícia que fala sobre o evento.
          </Typography>
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <CheckBox
            label='Nova Janela'
            name='english.isBlankTarget'
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <Button
            type='submit'
            fullWidth
            large
            color='primary'
            variant='contained'
            isSubmitting={isSubmitting}
          >
            Salvar
          </Button>
        </Grid>
      </Step>
     </FormFormik>
);

export { ContentForm };
