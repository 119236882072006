import React from 'react';

import { Grid } from '@material-ui/core';

import { format, parse } from 'date-fns';

import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js';
import { FabAdd, Table } from '~/components';
import { useDispatch, useSelector } from '~/store';
import { listEvents, removeEvents, showFormEvents } from '~/store/event';

import { EventsForm } from './EventsForm';
import { formatDate } from '~/helpers';
import { showConfirmation } from '~/store/confirm';

const EventsList: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { pagination, showForm, params } = useSelector((state) => state.event);

  const handleAdd = () => {
    dispatch(showFormEvents(true));
  };

  const handleEdit = (rowData: any) => {
    const data = JSON.parse(JSON.stringify(rowData));
    data.scheduledAt = format(parse(data.scheduledAt, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');
    if (data.portuguese.text) {
      const contentState = ContentState.createFromBlockArray(
        htmlToDraft(data.portuguese.text).contentBlocks,
      );
      data.portuguese.text = EditorState.createWithContent(contentState);
    }
    if (data.english.text) {
      const contentState = ContentState.createFromBlockArray(
        htmlToDraft(data.english.text).contentBlocks,
      );
      data.english.text = EditorState.createWithContent(contentState);
    }
    dispatch(showFormEvents(true, data));
  };

  const handleRemove = (id: string) => {
    dispatch(showConfirmation({
      message: 'Você tem certeza que deseja deletar esse item?',
      proceed: async () => {
        await dispatch(removeEvents(id));
        dispatch(listEvents(params));
      },
    }));
  };

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      <EventsForm
        open={showForm} />
      <Table
        title='Eventos'
        pagination={pagination}
        serverAction={listEvents}
        initialFilters={{
          sort: '-scheduledAt',
        }}
        columns={[
          { label: 'Data do Evento', name: 'scheduledAt' },
          { label: 'Título (Em português)', name: 'portuguese.title' },
          { label: 'Título (Em inglês)', name: 'english.title' },
        ]}
        data={pagination.docs.map((item: any) => ({
          ...item,
          scheduledAt: formatDate(new Date(item.scheduledAt), 'dd/MM/yyyy'),
        }))
        }
        actions={[
          {
            icon: 'add',
            tooltip: 'Adicionar Evento',
            isFreeAction: true,
            onClick: handleAdd,
          },
          {
            icon: 'create',
            tooltip: 'Editar Evento',
            onClick: (rowData: any) => {
              handleEdit(rowData);
            },
          },
          {
            icon: 'delete',
            tooltip: 'Remover Evento',
            onClick: (rowData: any) => {
              handleRemove(rowData.id);
            },
          },
        ]}
      />
      <FabAdd onClick={handleAdd} />
    </Grid>
  );
};

export { EventsList };
