import React, { useCallback, useEffect, useState } from 'react';
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableColumnDef,
  MUIDataTableColumnOptions,
  MUIDataTableProps,
} from 'mui-datatables';
import { debounce } from 'ts-debounce';
import {
  Grid, Icon, IconButton, TableCell,
} from '@material-ui/core';
import styled from '@emotion/styled';
import { useDispatch } from '~/store';
import { textLabels } from '~/components/Table/textLabels';
import { actionCell, root } from '~/components/Table/table.style';

interface ActionProps {
  icon: string;
  tooltip: string;
  onClick: (item?: any) => void
  isFreeAction?: boolean;
  className?: string;
  customRender?: (item?: any) => React.ReactNode;
}

interface TableProps extends MUIDataTableProps {
  data: any[];
  columns: MUIDataTableColumn[];
  pagination?: {
    docs: any[];
    totalDocs: number;
    page: number;
    totalPages: number;
    hasNextpage: boolean;
    nextPage: number | null;
    hasPrevPage: boolean;
    prevPage: number | null;
    pagingCounter: number;
  };
  initialFilters?: {
    sort?: string;
    page?: number;
    search?: string;
    limit?: number;
  };
  serverAction?: any;
  widthActions?: string;
  actions?: ActionProps[]
  classNames?: string;
}

type Sort = 'none' | 'desc' | 'asc';
type ColumnsSort = Sort[];

interface FilterState {
  sort?: string;
  page: number;
  search?: string;
  limit: number;
  processAction: boolean;
}

const Root = styled.div`${root}`;
const ActionCell = styled(TableCell)`${actionCell}`;

const Table: React.FunctionComponent<TableProps> = ({
  options = {
  },
  serverAction,
  classNames,
  data = [],
  pagination,
  columns,
  actions,
  widthActions = '98px',
  initialFilters = {
    sort: undefined,
    page: 1,
    search: undefined,
    limit: 5,
  },
  ...others
}) => {
  const dispatch = useDispatch();
  const [columnSortDirection, setColumnSortDirection] = useState<ColumnsSort>([]);
  const [filters, setFilters] = useState<FilterState>({
    ...initialFilters,
    processAction: false,
    page: initialFilters.page || 1,
    limit: initialFilters.limit || 5,
  });

  const loadDataBySearch = (processed: FilterState) => {
    if (!serverAction) return;
    const { processAction, ...currentFilters } = processed;
    dispatch(serverAction(currentFilters));
  };

  const debounceLoadData = useCallback(debounce(loadDataBySearch, 1000), []);

  useCallback(() => {
    const sorts: ColumnsSort = [];
    columns.forEach(() => {
      sorts.push('none');
    });
    setColumnSortDirection(sorts);
  }, [columns]);

  useEffect(() => {
    if (filters.processAction) dispatch(serverAction(filters));
    else debounceLoadData(filters);
  }, [filters, serverAction, dispatch, debounceLoadData]);

  const handleSort = (changedColumn: string, order: Sort) => {
    const positionSort = columns.findIndex((item) => item.name === changedColumn);
    const columnsSort: Sort[] = columnSortDirection.map(() => 'none');
    columnsSort[positionSort] = order;
    setColumnSortDirection(columnsSort);
    const columnName = columns[positionSort].name;
    const sort = order === 'desc' ? `-${columnName}` : columnName;
    setFilters({
      sort,
      page: filters.page,
      search: filters.search,
      limit: filters.limit,
      processAction: true,
    });
  };

  const serverOptions = serverAction ? {
    onColumnSortChange: (changedColumn: string, direction: string) => {
      let order: Sort = 'desc';
      if (direction === 'ascending') {
        order = 'asc';
      }

      handleSort(changedColumn, order);
    },
    onSearchChange: (searchText: string) => {
      setFilters({
        ...filters,
        processAction: false,
        search: searchText,
      });
    },
    onChangePage: (currentPage: number) => {
      setFilters({
        ...filters,
        page: currentPage + 1,
        processAction: true,
      });
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setFilters({
        ...filters,
        limit: numberOfRows,
        page: 1,
        processAction: true,
      });
    },
  } : {};

  const columnsAux = columns.map((i, index) => {
    const optionsColumn: MUIDataTableColumnOptions = i.options ? {
      ...i.options,
      sortDirection: columnSortDirection[index],
    } : {
      sortDirection: columnSortDirection[index],
    };
    const result: MUIDataTableColumnDef = {
      ...i,
      options: optionsColumn,
    };
    return result;
  });

  if (actions) {
    columnsAux.push({
      label: 'Ações',
      name: 'action',
      options: {
        sort: false,
        filter: false,
        // eslint-disable-next-line react/display-name
        customHeadRender: (columnMeta) => (
          <ActionCell width={widthActions}>
            {columnMeta.label}
          </ActionCell>
        ),
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta) => (
          <Grid justify='space-between' container>
            {
              actions
                .filter((action) => !action.isFreeAction)
                .map((action, index) => (
                  <IconButton
                    size={'small'}
                    className={action.className}
                    key={action.icon + index}
                    onClick={() => { action.onClick(data[tableMeta.rowIndex]); }}
                  >
                    <Icon>{action.icon}</Icon>
                  </IconButton>
                ))
            }
          </Grid>
        ),
      },
    });
  }

  return (
    <Root className={classNames}>
      <MUIDataTable
        columns={columnsAux}
        data={data}
        options={{
          searchText: filters.search,
          rowsPerPage: 5,
          rowsPerPageOptions: [5, 20, 100],
          serverSide: !!serverAction,
          ...serverOptions,
          page: pagination ? pagination.page - 1 : undefined,
          count: pagination?.totalDocs,
          selectableRows: 'none',
          customToolbar: actions ? () => actions
            .filter((action) => action.isFreeAction)
            .map((action, index) => (
              <IconButton
                className={action.className}
                key={action.icon + index}
                onClick={() => { action.onClick(); }}
              >
                <Icon>{action.icon}</Icon>
              </IconButton>
            )) : undefined,
          textLabels,
          filter: false,
          print: false,
          fixedHeaderOptions: {
            xAxis: true,
            yAxis: true,
          },
          ...options,
        }}
        {...others}
      />
    </Root>
  );
};

export { Table };
