
import { Dispatch, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import axios from 'axios';
import { api } from '../api';

export const Types = {
  LIST_FUNDS: 'funds/LIST',
  SHOW_FORM_FUNDS: 'funds/SHOW_FORM',
  CREATE_FUNDS: 'funds/CREATE',
  UPDATE_FUNDS: 'funds/UPDATE',
  REMOVE_FUNDS: 'funds/REMOVE',
};


interface FundsListAction {
  type: typeof Types.LIST_FUNDS,
  payload: any
}

interface FundsCreateAction {
  type: typeof Types.CREATE_FUNDS,
  payload: any
}

interface FundsUpdateAction {
  type: typeof Types.UPDATE_FUNDS,
  payload: any
}

interface FundsRemoveAction {
  type: typeof Types.REMOVE_FUNDS,
  payload: any
}

export interface FundState {
  list: [];
  showForm: boolean;
  editData: null | object;
}

const initialState: FundState = {
  list: [],
  showForm: false,
  editData: null,
};


export default function reducer(
  state = initialState,
  { type, payload }:
  any,
) {
  switch (type) {
    case Types.LIST_FUNDS:
      return { ...state, list: payload };
    case Types.SHOW_FORM_FUNDS:
      return { ...state, showForm: payload.show, editData: payload.data };
    case Types.CREATE_FUNDS:
      return { ...state, list: [payload] };
    case Types.UPDATE_FUNDS:
      return {
        ...state,
        list: [payload],
      };
    case Types.REMOVE_FUNDS:
      return { ...state, list: [] };
    default:
      return state;
  }
}

export const listFunds: ActionCreator<
ThunkAction<
Promise<FundsListAction>,
{},
null,
FundsListAction
>> = (type: string) => async (dispatch: Dispatch): Promise<FundsListAction> => {
  const data = await api.get('/funds', {
    params: {
      type,
    },
  });
  return dispatch({
    payload: data,
    type: Types.LIST_FUNDS,
  });
};

interface ResultAWS {
  readUrl: string;
  uploadUrl: string
}

const uploadFileAWS = async (attachment: File): Promise<ResultAWS> => {
  const resultAWS: ResultAWS = await api.post('/public-file', {
    mimeType: attachment.type,
  });
  await axios.put(resultAWS.uploadUrl, attachment, { headers: { 'Content-Type': attachment.type } });
  return resultAWS;
};

export const createFunds: ActionCreator<
ThunkAction<
Promise<FundsCreateAction>,
{},
null,
FundsCreateAction
>> = (values: any) => async (dispatch: Dispatch): Promise<FundsCreateAction> => {
  const data = { ...values };
  const portugueseFile = data.portuguese.attachment;
  if (portugueseFile) {
    const result = await uploadFileAWS(portugueseFile);
    data.portuguese.attachment = result.readUrl;
  }
  const result = await api.post('/funds', data);
  return dispatch({
    payload: result,
    type: Types.CREATE_FUNDS,
  });
};

export const updateFunds: ActionCreator<
ThunkAction<
Promise<FundsUpdateAction>,
{},
null,
FundsUpdateAction
>> = (values: any) => async (dispatch: Dispatch): Promise<FundsUpdateAction> => {
  const data = { ...values };
  const portugueseFile = data.portuguese.attachment;
  if (portugueseFile) {
    if (portugueseFile.s3) {
      data.portuguese.attachment = portugueseFile.url;
    } else {
      const result = await uploadFileAWS(portugueseFile);
      data.portuguese.attachment = result.readUrl;
    }
  }
  const result = await api.put(`/funds/${values.id}`, data);
  return dispatch({
    payload: result,
    type: Types.UPDATE_FUNDS,
  });
};

export const removeFunds: ActionCreator<
ThunkAction<
Promise<FundsRemoveAction>,
{},
null,
FundsRemoveAction
>> = (id: string) => async (dispatch: Dispatch): Promise<FundsRemoveAction> => {
  await api.delete(`/funds/${id}`);
  return dispatch({
    payload: id,
    type: Types.REMOVE_FUNDS,
  });
};

export const showFormFunds = (show: boolean, data: any = null) => (dispatch: any) => {
  dispatch({ type: Types.SHOW_FORM_FUNDS, payload: { show, data } });
};
