import * as yup from 'yup';

export const validationSchema = () => {
  const schema = {
    portuguese: yup.object().shape({
      description: yup.string().required(),
      attachment: yup.mixed().required(),
    }),
  };
  return yup.object().shape(schema);
};
