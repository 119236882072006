/* eslint array-callback-return: 0 */ //
import axios, { AxiosResponse } from 'axios';

import { store } from './store';
import { showAlert } from './alert';
import { logout } from '~/store/auth';

const hostName = process.env.NODE_ENV === 'development' ? 'localhost:3001' : 'admin-site.jiveinvestments.com';
const apiUrl = process.env.NODE_ENV === 'development' ? `http://${hostName}` : `https://${hostName}`;
const wsUrl = process.env.NODE_ENV === 'development' ? `ws://${hostName}/ws` : `wss://${hostName}/ws`;

const api = axios.create({
  baseURL: `${apiUrl}/api`,
});


const interceptors = [
  (response: AxiosResponse) => response.data,
  (e: any): Promise<AxiosResponse> => {
    if (e && e.response) {
      const { data } = e.response.data;
      const { dispatch } = store;
      if (e.response.status === 401) {
        dispatch(logout());
      }
      if (data) {
        data.forEach((err: any) => {
          dispatch(showAlert({ message: err.msg, type: 'error' }));
        });
      }
    }
    return Promise.reject(e);
  },
];

api.interceptors.response.use(...interceptors);

export {
  api, apiUrl, wsUrl,
};
