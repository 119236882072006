import { css } from '@emotion/core';

const root = ({ theme, bgColor, colorWeight }: any) => css`
    ${bgColor && bgColor !== 'default' ? `
    background-color: ${theme.palette[bgColor][colorWeight]};
    color: ${theme.palette[bgColor].contrastText};
    ` : ''}
`;

export { root };
