import React, { useEffect, useState } from 'react';

import {
  Grid, Typography,
} from '@material-ui/core';

import { Form as FormFormik } from 'formik';

import styled from '@emotion/styled';
import {
  Button, TextField, Editor, Autocomplet, InputFile, Cropper, OnCompletProps,
} from '~/components';


import { step } from './contentForm.style';
import { useDispatch, useSelector } from '~/store';
import { listAllHashtags } from '~/store/hashtag';
import {
  base64ToFile, cropperCenter, fileToBase64,
} from '~/helpers';

const Step: any = styled(Grid)`${step}`;

interface ContentFormProps {
  isSubmitting: boolean;
  activeStep: number;
  setActiveStep: any;
  setFieldValue: any;
  values: any;
  validateForm: () => Promise<any>;
  setFieldTouched: (field: string) => void;
}

interface ImagesProps {
  base64: string;
  file?: File;
}

const ContentForm: React.FunctionComponent<ContentFormProps> = ({
  isSubmitting,
  activeStep,
  setActiveStep,
  values,
  setFieldValue,
  validateForm,
  setFieldTouched,
}) => {
  const dispatch = useDispatch();
  const hashtags = useSelector((state) => state.hashtag.listAll);
  const [images, setImages] = useState<ImagesProps>({
    base64: '',
  });

  useEffect(() => {
    if (values.attachment) {
      fileToBase64(values.attachment)
        .then((base64) => {
          setImages({
            base64,
            file: values.attachment,
          });
        });
    }
  }, [values]);

  useEffect(() => {
    dispatch(listAllHashtags());
  }, [dispatch]);

  const handleOptionLabel = (option: any) => {
    if (option.portuguese.description
      && !option.english.description) return option.portuguese.description;
    if (option.english.description
      && !option.portuguese.description) return option.english.description;
    return `${option.portuguese.description}/${option.english.description}`;
  };

  const handleChange = async ({ target }: any) => {
    const file = target.files[0];
    setImages({
      base64: await fileToBase64(file),
      file,
    });
  };

  const handleCropComplet = async ({ fileResult, base64 }: OnCompletProps) => {
    if (images.file) {
      const attachmentLarge = fileResult;
      const attachmentSmall = await base64ToFile(await cropperCenter(base64, {
        x: 0,
        y: 0,
        width: 370,
        height: 309,
      }), images.file.name, images.file.type);
      setFieldValue('attachmentLarge', attachmentLarge);
      setFieldValue('attachmentSmall', attachmentSmall);
    }
  };

  return (
    <FormFormik>
      <Step
        container
        spacing={1}
        active={String(activeStep === 0)}
        justify="center"
        alignItems="center"
        direction="column"
      >
        <Grid
          item
          sm={12}
          md={12}>
          <InputFile
            label='Imagem da notícia'
            name='attachment'
            multiple={false}
            textDrag={(
              <span>
                Solte o arquivo aqui ou
                <br/>
                clique para procurar o arquivo
                <br/>
                e escolher em seguida
              </span>
            )}
            onChange={handleChange}
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <Typography variant='caption'>
            ATENÇÃO: Utilize somente arquivos de imagem e que tenha, pelo menos, 970
            pixels de largura ou 545 pixels de altura. Imagens com menos que
            isso desfigurará o layout prejudicando o resultado final.
          </Typography>
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <Button
            fullWidth
            large
            onClick={() => {
              validateForm().then((res) => {
                setFieldTouched('attachment');
                if (!res.attachment) setActiveStep(1);
              });
            }}
            color='primary'
            variant='contained'
          >
            Avançar
          </Button>
        </Grid>
      </Step>
      <Step
        container
        spacing={1}
        active={String(activeStep === 1)}
        justify="center"
        alignItems="center"
        direction="column"
      >
        { images.base64 && activeStep === 1 && (
          <Cropper
            className='cropper'
            image={images.base64}
            file={images.file}
            onCropComplete={handleCropComplet}
          />
        )}
        <Grid
          item
          sm={12}
          md={12}>
          <Typography variant='caption'>
            ATENÇÃO: Você pode ajustar a imagem como desejar e até ampliá-la,
            mas lembre-se que ao ampliar uma imagem, a mesma perderá qualidade
            prejudicando o resultado final.
          </Typography>
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <Button
            fullWidth
            large
            onClick={() => {
              setActiveStep(2);
            }}
            color='primary'
            variant='contained'
          >
            Avançar
          </Button>
        </Grid>
      </Step>
      <Step
        container
        spacing={1}
        active={String(activeStep === 2)}
        justify="center"
        alignItems="center"
        direction="column"
      >
        <Grid
          item
          sm={12}
          md={12}>
          <TextField
            label='Data exata da notícia'
            name='postedAt'
            type='date'
            helper='Data para as duas linguagens'
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <Typography variant='caption'>
            ATENÇÃO: Caso essa notícia seja apenas para a versão em inglês,
            apenas coloque a data exata de quando a notícia foi publicada e
            pule para a próxima etapa.
          </Typography>
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <TextField
            label='Título (em português) com até 200 caracteres'
            name='portuguese.title'
            fullWidth
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <TextField
            label='Fonte (em português)'
            name='portuguese.source'
            fullWidth
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <Typography variant='caption'>
            Texto da notícia: O sistema permite uma certa liberdade na diagramação do texto,
            mas sugerimos cuidado para que essa diagramação não fique fora do padrão do site,
            portanto, sempre limpe
            a diagramação do texto colado usando a ferramenta de apagar (Ícone da borracha).
          </Typography>
        </Grid>
        <Grid
          className='editor'
          item
          sm={12}
          md={12}>
          <Editor
            name='portuguese.text'
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <Autocomplet
            multiple
            options={hashtags}
            getOptionLabel={handleOptionLabel}
            fullWidth
            filterSelectedOptions
            label='Hashtags'
            name='hashtags'
            size='small'
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <Button
            fullWidth
            large
            onClick={() => {
              validateForm().then((res) => {
                setFieldTouched('portuguese.title');
                setFieldTouched('portuguese.text');
                if (!res.portuguese) setActiveStep(3);
              });
            }}
            color='primary'
            variant='contained'
          >
            Avançar
          </Button>
        </Grid>
      </Step>
      <Step
        container
        active={String(activeStep === 3)}
        spacing={1}
        direction='row'>

        <Grid
          item
          sm={12}
          md={12}>
          <TextField
            label='Data exata da notícia'
            name='postedAt'
            type='date'
            helper='Data para as duas linguagens'
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <TextField
            label='Título (em inglês) com até 200 caracteres'
            name='english.title'
            fullWidth
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <TextField
            label='Fonte (em inglês)'
            name='english.source'
            fullWidth
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <Typography variant='caption'>
            Texto da notícia: O sistema permite uma certa liberdade na diagramação do texto,
            mas sugerimos cuidado para que essa diagramação não fique fora do padrão do site,
            portanto, sempre limpe a diagramação do texto colado usando a ferramenta de
            apagar (Ícone da borracha).
          </Typography>
        </Grid>
        <Grid
          className='editor'
          item
          sm={12}
          md={12}>
          <Editor
            name='english.text'
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <Autocomplet
            multiple
            options={hashtags}
            getOptionLabel={handleOptionLabel}
            fullWidth
            filterSelectedOptions
            label='Hashtags'
            name='hashtags'
            size='small'
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <Button
            type='submit'
            fullWidth
            large
            color='primary'
            variant='contained'
            isSubmitting={isSubmitting}
          >
            Salvar
          </Button>
        </Grid>
      </Step>
    </FormFormik>
  );
};

export { ContentForm };
