import React from 'react';


import { Formik, Form } from 'formik';

import { Grid, Typography } from '@material-ui/core';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from '~/store';
import { showFormUsers, updateUsers, updatePasswordUsers } from '~/store/user';

import {
  Dialog, DialogTitle, DialogContent, TextField, Button, CheckBox,
} from '~/components';

import { validationEditPasswordSchema, validationEditSchema } from './components';
import { showAlert } from '~/store/alert';
import { contentEditUser } from '~/containers/Users/components/contentForm.style';

const Content = styled(Grid)`
${contentEditUser}
`;


interface UsersFormProps {
  open: boolean;
}

const UsersEditForm: React.FunctionComponent<UsersFormProps> = ({
  open,
}) => {
  const dispatch = useDispatch();
  const { editData }: any = useSelector((state) => state.user);

  const handleClose = () => {
    dispatch(showFormUsers(false));
  };

  const handleSubmit = async (values: any) => {
    try {
      const data = JSON.parse(JSON.stringify(values));
      await dispatch(updateUsers(data));
      dispatch(showAlert({
        message: 'Usuário salvo com sucesso',
      }));
    } catch (_) {
      dispatch(showAlert({
        message: 'Falha ao realizar a operação',
        type: 'error',
      }));
    }
  };


  const handleSubmitPassword = async (values: any) => {
    try {
      const data = JSON.parse(JSON.stringify(values));
      await dispatch(updatePasswordUsers({ ...data, id: editData?.id }));
      dispatch(showAlert({
        message: 'Usuário salvo com sucesso',
      }));
    } catch (_) {
      dispatch(showAlert({
        message: 'Falha ao realizar a operação',
        type: 'error',
      }));
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth={false}
    >
      <DialogTitle
        title='Cadastrar um usuário'
        onClose={handleClose}
      />
      <DialogContent>
        <Formik
          initialValues={editData || {}}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={validationEditSchema}
        >
          {({
            isSubmitting,
          }) => (
            <Form>
              <Grid
                spacing={1}
                container>
                <Grid
                  item
                  sm={12}
                  md={12}>
                  <Typography variant='subtitle1'>Alterar dados</Typography>
                  <hr />
                </Grid>
                <Content
                  spacing={1}
                  container>
                <Grid
                  item
                  sm={12}
                  md={12}>
                  <TextField
                    label='Nome'
                    name='name'
                    autoComplete='off'
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  md={12}>
                  <TextField
                    label='Email'
                    name='email'
                    autoComplete='off'
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  md={12}>
                  <Button
                    type='submit'
                    fullWidth
                    large
                    color='primary'
                    variant='contained'
                    isSubmitting={isSubmitting}
                  >
                    Salvar
                  </Button>
                </Grid>
                </Content>
              </Grid>
            </Form>
          )}
        </Formik>
        <Formik
          initialValues={{
            password: '',
            passwordConfirm: '',
            changePassword: false,
          }}
          onSubmit={handleSubmitPassword}
          enableReinitialize
          validationSchema={validationEditPasswordSchema}
        >
          {({
            isSubmitting,
          }) => (
            <Form>
              <Grid
                spacing={1}
                container>
                <Grid
                  item
                  sm={12}
                  md={12}>
                  <Typography variant='subtitle1'>Alterar Senha</Typography>
                  <hr />
                </Grid>
                <Content
                  spacing={1}
                  container>
                <Grid
                  item
                  sm={12}
                  md={12}>
                  <TextField
                    label='Senha'
                    autoComplete='off'
                    name='password'
                    type='password'
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  md={12}>
                  <TextField
                    autoComplete='off'
                    label='Confirmar Senha'
                    name='passwordConfirm'
                    type='password'
                    fullWidth
                  />
                </Grid>
                  <Grid
                    item
                    sm={12}
                    md={12}>
                    <CheckBox
                      label='Forçar alteração de senha no primeiro acesso'
                      name='changePassword'
                    />
                  </Grid>
                <Grid
                  item
                  sm={12}
                  md={12}>
                  <Button
                    type='submit'
                    fullWidth
                    large
                    color='primary'
                    variant='contained'
                    isSubmitting={isSubmitting}
                  >
                    Salvar
                  </Button>
                </Grid>
                </Content>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export { UsersEditForm };
