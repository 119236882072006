
import { Dispatch, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import axios from 'axios';
import { api } from '../api';

export const Types = {
  LIST_COMPLIANCES: 'compliances/LIST',
  SHOW_FORM_COMPLIANCES: 'compliances/SHOW_FORM',
  CREATE_COMPLIANCES: 'compliances/CREATE',
  UPDATE_COMPLIANCES: 'compliances/UPDATE',
  REMOVE_COMPLIANCES: 'compliances/REMOVE',
};


interface CompliancesListAction {
  type: typeof Types.LIST_COMPLIANCES,
  payload: any
}

interface CompliancesCreateAction {
  type: typeof Types.CREATE_COMPLIANCES,
  payload: any
}

interface CompliancesUpdateAction {
  type: typeof Types.UPDATE_COMPLIANCES,
  payload: any
}

interface CompliancesRemoveAction {
  type: typeof Types.REMOVE_COMPLIANCES,
  payload: any
}

export interface ComplianceState {
  pagination: {
    docs: any[];
    totalDocs: number;
    page: number;
    totalPages: number;
    hasNextpage: boolean;
    nextPage: number | null;
    hasPrevPage: boolean;
    prevPage: number | null;
    pagingCounter: number;
  };
  params: object;
  showForm: boolean;
  editData: null | object;
}

const initialState: ComplianceState = {
  pagination: {
    docs: [],
    totalDocs: 0,
    page: 1,
    totalPages: 1,
    hasNextpage: false,
    nextPage: null,
    hasPrevPage: false,
    prevPage: null,
    pagingCounter: 1,
  },
  params: {},
  showForm: false,
  editData: null,
};


export default function reducer(
  state = initialState,
  { type, payload }:
  any,
) {
  switch (type) {
    case Types.LIST_COMPLIANCES:
      return { ...state, pagination: payload.data, params: payload.params };
    case Types.SHOW_FORM_COMPLIANCES:
      return { ...state, showForm: payload.show, editData: payload.data };
    default:
      return state;
  }
}

interface Pagination {
  search?: string;
  page: number;
  sort: string;
  limit: number;
}

export const listCompliances: ActionCreator<
ThunkAction<
Promise<CompliancesListAction>,
{},
null,
CompliancesListAction
>> = (params: Pagination) => async (dispatch: Dispatch): Promise<CompliancesListAction> => {
  const data = await api.get('/compliances', {
    params,
  });
  return dispatch({
    payload: {
      data,
      params,
    },
    type: Types.LIST_COMPLIANCES,
  });
};

interface ResultAWS {
  readUrl: string;
  uploadUrl: string
}

const uploadFileAWS = async (attachment: File): Promise<ResultAWS> => {
  const resultAWS: ResultAWS = await api.post('/public-file', {
    mimeType: attachment.type,
  });
  await axios.put(resultAWS.uploadUrl, attachment, { headers: { 'Content-Type': attachment.type } });
  return resultAWS;
};

export const createCompliances: ActionCreator<
ThunkAction<
Promise<CompliancesCreateAction>,
{},
null,
CompliancesCreateAction
>> = (values: any) => async (dispatch: Dispatch): Promise<CompliancesCreateAction> => {
  const data = { ...values };
  const portugueseFile = data.portuguese.attachment;
  const englishFile = data.english.attachment;
  if (portugueseFile) {
    const result = await uploadFileAWS(portugueseFile);
    data.portuguese.attachment = result.readUrl;
  }
  if (englishFile) {
    const result = await uploadFileAWS(englishFile);
    data.english.attachment = result.readUrl;
  }
  const result = await api.post('/compliances', data);
  return dispatch({
    payload: result,
    type: Types.CREATE_COMPLIANCES,
  });
};

export const updateCompliances: ActionCreator<
ThunkAction<
Promise<CompliancesUpdateAction>,
{},
null,
CompliancesUpdateAction
>> = (values: any) => async (dispatch: Dispatch): Promise<CompliancesUpdateAction> => {
  const data = { ...values };
  const portugueseFile = data.portuguese.attachment;
  const englishFile = data.english.attachment;
  if (portugueseFile) {
    if (portugueseFile.s3) {
      data.portuguese.attachment = portugueseFile.url;
    } else {
      const result = await uploadFileAWS(portugueseFile);
      data.portuguese.attachment = result.readUrl;
    }
  }
  if (englishFile) {
    if (englishFile.s3) {
      data.english.attachment = englishFile.url;
    } else {
      const result = await uploadFileAWS(englishFile);
      data.english.attachment = result.readUrl;
    }
  }
  const result = await api.put(`/compliances/${values.id}`, data);
  return dispatch({
    payload: result,
    type: Types.UPDATE_COMPLIANCES,
  });
};

export const removeCompliances: ActionCreator<
ThunkAction<
Promise<CompliancesRemoveAction>,
{},
null,
CompliancesRemoveAction
>> = (id: string) => async (dispatch: Dispatch): Promise<CompliancesRemoveAction> => {
  await api.delete(`/compliances/${id}`);
  return dispatch({
    payload: id,
    type: Types.REMOVE_COMPLIANCES,
  });
};

export const showFormCompliances = (show: boolean, data: any = null) => (dispatch: any) => {
  dispatch({ type: Types.SHOW_FORM_COMPLIANCES, payload: { show, data } });
};
