import { useEffect, useRef } from 'react';
import { addMinutes, format } from 'date-fns';

export const stringToNumber = (value: string) => {
  if (!value) return value;
  return value.replace(/\D/g, '');
};

export const currencyToNumber = (value: string) => {
  if (!value) return value;
  return Number(value.replace('R$ ', '').replace(/\./g, '').replace(',', '.'));
};


export const formatCPF = (cpf: string) => {
  if (!cpf) return cpf;
  const cleanValue = cpf.replace(/\D/g, '');
  return cleanValue.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4');
};

export const formatCNPJ = (cnpj: string) => {
  if (!cnpj) return cnpj;
  const cleanValue = cnpj.replace(/\D/g, '');
  return cleanValue.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/, '$1.$2.$3/$4-$5');
};

export const fileToBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result?.toString());
  reader.onerror = (error) => reject(error);
});

export const base64ToFile = (url:string, filename:string, mimeType?:string) => (fetch(url)
  .then((res) => ({
    buffer: res.arrayBuffer(),
    contentType: res.headers.get('Content-Type') || 'application/octet-stream',
  }))
  .then(async (result) => new File([await result.buffer],
    filename,
    { type: mimeType || result.contentType }))
);

export const getKeyByValue = (
  object: any,
  value: number | string,
) => Object.keys(object)
  .find((key: number | string) => object[key] === value);


export declare type Area = {
  width: number;
  height: number;
  x: number;
  y: number;
};

export const getCroppedImg = (
  imageB64: string,
  crop: Area,
): Promise<string> => new Promise((resolve, reject) => {
  const canvas = document.createElement('canvas');
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx: any = canvas.getContext('2d');

  const image = new Image();

  image.onload = function onLoad() {
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      if (blob) {
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          resolve(reader.result?.toString());
        };
      } else reject();
    });
  };

  image.src = imageB64;
});


export const cropperCenter = (
  imageB64: string,
  crop: Area,
): Promise<string> => new Promise((resolve, reject) => {
  const canvas = document.createElement('canvas');
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx: any = canvas.getContext('2d');

  const image = new Image();

  image.onload = function onLoad() {
    const sourceX = 0;
    const sourceY = 0;
    const sourceWidth = image.width;
    const sourceHeight = image.height;
    const destWidth = sourceWidth;
    const destHeight = sourceHeight;
    const destX = canvas.width / 2 - destWidth / 2;
    const destY = canvas.height / 2 - destHeight / 2;

    ctx.drawImage(
      image,
      sourceX,
      sourceY,
      sourceWidth,
      sourceHeight,
      destX,
      destY,
      destWidth,
      destHeight,
    );

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      if (blob) {
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          resolve(reader.result?.toString());
        };
      } else reject();
    });
  };

  image.src = imageB64;
});

export const htmlToText = (html: string): string => {
  if (!html) return '';
  const regex = /(<([^>]+)>)/ig;
  return html.replace(regex, '').trim();
};

export const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

export const formatDate = (date: Date,
  formatOutput: string): string => format(
  addMinutes(date, date.getTimezoneOffset()),
  formatOutput,
);
