import React from 'react';

import {
  Grid, Typography,
} from '@material-ui/core';

import { Form as FormFormik } from 'formik';

import styled from '@emotion/styled';
import {
  Button, TextField,
} from '~/components';

import { content } from './contentForm.style';


const Content: any = styled(Grid)`${content}`;

interface ContentFormProps {
  isSubmitting: boolean;
}

const ContentForm: React.FunctionComponent<ContentFormProps> = ({
  isSubmitting,
}) => (
    <FormFormik>
      <Content
        container
        spacing={1}
        justify="center"
        alignItems="center"
        direction="column"
      >
        <ul>
          <li>
            <Typography>
              As hashtags são categorizações das notícias para que o usuário possa
              ver um grupo de notícias relacionadas. Sugerimos que, antes de inserir
              uma notícia, crie a(s) hashtag(s) relacionada(s) a notícia.
              Geralmente a própria notícia informa as hashtags relacionadas.
            </Typography>
          </li>
          <li>
            <Typography>
              Mesmo que a notícia seja somente em uma das versões, sugerimos que se
              crie as hashtags relacionadas para
              ambas as versões (português e inglês). Dessa forma, o sistema
              não terá risco de possuir
              hashtags iguais, mas em grupos (línguas) diferentes.
            </Typography>
          </li>
        </ul>
        <Grid
          item
          sm={12}
          md={12}>
          <TextField
            label='Descrição (Em português)'
            name='portuguese.description'
            fullWidth
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <TextField
            label='Descrição (Em inglês)'
            name='english.description'
            fullWidth
          />
        </Grid>
        <Button
        type='submit'
        fullWidth
        large
        color='primary'
        variant='contained'
        isSubmitting={isSubmitting}
      >
        Salvar
      </Button>
      </Content>
     </FormFormik>
);

export { ContentForm };
