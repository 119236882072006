
import { applyMiddleware, createStore } from 'redux';
import promise from 'redux-promise';
import thunk from 'redux-thunk';

import reducers from './reducers';

const store = applyMiddleware(thunk, promise)(createStore)(
  reducers,
);

export { store };
