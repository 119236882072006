import React from 'react';

export const Types = {
  SHOW_ALERT: 'snackbar/SHOW_ALERT',
  HIDE_ALERT: 'snackbar/TOKEN_VALIDATED',
};


interface ShowAlertAction {
  type: typeof Types.SHOW_ALERT,
  payload: string
}

interface HideAlertAction {
  type: typeof Types.HIDE_ALERT,
  payload?: any
}

const initialState = {
  snackbarOpen: false,
  snackbarMessage: '',
  snackbarType: 'success',
};


export default function reducer(
  state = initialState,
  { type, payload }:
  any,
) {
  switch (type) {
    case Types.SHOW_ALERT:
      return {
        ...state,
        snackbarOpen: true,
        snackbarMessage: payload.message,
        snackbarType: payload.type,
      };
    case Types.HIDE_ALERT:
      return {
        ...state,
        snackbarOpen: false,
      };
    default:
      return state;
  }
}

interface ShowAlertProps {
  message: React.ReactNode
  type?: 'success' | 'info' | 'warning' | 'error'
}

export const showAlert = ({ message, type = 'success' }: ShowAlertProps) => (dispatch: any) => dispatch({
  type: Types.SHOW_ALERT,
  payload: { message, type },
});

export const clearAlert = () => (dispatch: any) => dispatch({ type: Types.HIDE_ALERT });
