import { css } from '@emotion/core';

const content = () => css`
  margin: auto;
  width: 350px;
  
  > div {
      min-height: 68px;
  }
  
  button {
    margin-bottom: 8px;
  }
`;

const contentEditUser = css`
  margin: 8px auto;
  width: 350px;
  > div {
      min-height: 68px;
  }
`;

export { content, contentEditUser };
