import { css } from '@emotion/core';

const root = css`
    #submit-basic-profile {
      margin-bottom: 16px;
    }
    .MuiFormControl-root {
      min-height: 68px;
    }
`;

const contentTitle = css`
    text-align: center;
        
    > h5 {
        margin-bottom: 16px;
    }
`;

export { contentTitle, root };
