import { css } from '@emotion/core';

const content = css`
  margin: auto;
  width: 350px;
  
  > div:nth-of-type(1) {
    width: 100%;
    min-height: 68px;
  }
`;

export { content };
