import React from 'react';

import {
  List,
} from '@material-ui/core';

import styled from '@emotion/styled';
import { MenuItemDrawer } from './MenuItemDrawer';


import { listRoot } from './menuDrawer.style';
import { useSelector } from '~/store';

const ListRoot = styled(List)`${listRoot}`;


const MenuDrawer: React.FunctionComponent = () => {
  const { role } = useSelector((state) => state.profile.current);
  return (
    <ListRoot className='main-menu'>
      <MenuItemDrawer
        description='Home'
        icon='home'
        action='/home'
      />
      <MenuItemDrawer
        description='Fundos'
        icon='trending_up'
        subMenu={[
          {
            description: 'Distressed FIM CP IE',
            action: '/funds/fim-cp-ie',
          },
          {
            description: 'Distressed FIM II CP',
            action: '/funds/fim-ii-cp',
          },
          {
            description: 'Distressed FIM III CP',
            action: '/funds/fim-iii-cp',
          },
        ]}
      />
      <MenuItemDrawer
        description='News'
        icon='speaker_notes'
        subMenu={[
          {
            description: 'Hashtags',
            action: '/hashtags',
          },
          {
            description: 'Jive na mídia',
            action: '/news',
          },
          {
            description: 'Eventos',
            action: '/events',
          },
        ]}
      />
      <MenuItemDrawer
        description='Oportunidades'
        icon='how_to_reg'
        action='/opportunities'
      />
      <MenuItemDrawer
        description='Compliance'
        icon='library_books'
        action='/compliances'
      />
      {
        role === 'SUPER_ADMIN' && (
          <MenuItemDrawer
            description='Usuários'
            icon='persons'
            action='/users'
          />
        )
      }
    </ListRoot>
  );
};

export { MenuDrawer };
