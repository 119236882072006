import React, { useState } from 'react';

import Root from 'react-easy-crop';

import { Area, Point } from 'react-easy-crop/types';
import { base64ToFile, getCroppedImg } from '~/helpers';

interface PositionCropProps {
  x: number;
  y: number;
}

export interface OnCompletProps {
  croppedArea: Area,
  croppedAreaPixels: Area,
  base64: string
  fileResult?: File;
}

interface CropperProps {
  className?: string;
  zoom?: number;
  positionCrop?: PositionCropProps;
  aspect?: number;
  image?: string;
  onCropComplete: (result: OnCompletProps) => void;
  onZoomChange?: (zoomValue: number) => void;
  onCropChange?: (crop: Point) => void;
  file?: File;
}

const Cropper: React.FunctionComponent<CropperProps> = ({
  className,
  zoom = 1,
  positionCrop = {
    x: 0,
    y: 0,
  },
  aspect = 16 / 9,
  image = '',
  file,
  onCropComplete,
  onZoomChange,
  onCropChange,
}) => {
  const [cropSettings, setCropSettings] = useState({
    crop: positionCrop,
    zoom,
    aspect,
  });


  const handleCropChange = (crop: Point) => {
    setCropSettings({
      ...cropSettings,
      crop,
    });
    if (onCropChange) onCropChange(crop);
  };

  const handleCropComplete = async (croppedArea: Area,
    croppedAreaPixels: Area): Promise<void> => {
    const cropped: string = await getCroppedImg(image, croppedAreaPixels);
    let fileResult;
    if (file) fileResult = await base64ToFile(cropped, file.name, file.type);
    onCropComplete({
      croppedArea,
      croppedAreaPixels,
      base64: cropped,
      fileResult,
    });
  };


  const handleZoomChange = (zoomValue: number) => {
    setCropSettings({
      ...cropSettings,
      zoom: zoomValue,
    });
    if (onZoomChange) onZoomChange(zoomValue);
  };

  return (
    <Root
      classes={{
        containerClassName: className,
      }}
      image={image}
      crop={cropSettings.crop}
      zoom={cropSettings.zoom}
      aspect={cropSettings.aspect}
      onCropChange={handleCropChange}
      onCropComplete={handleCropComplete}
      onZoomChange={handleZoomChange}
    />
  );
};

export { Cropper };
