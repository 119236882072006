import * as yup from 'yup';

export const validationSchema = () => {
  const schema = {
    password: yup.string().required(),
    passwordConfirm: yup.mixed().test('match', 'As senhas não coincidem', function valid(value) {
      const { password } = this.parent;
      return value === password;
    }).required(),
  };
  return yup.object().shape(schema);
};
