import React from 'react';

import { useHistory } from 'react-router-dom';

import { Button as Root, ButtonProps } from '@material-ui/core';

interface ButtonRouteProps extends ButtonProps {
  to: string,
  children: React.ReactNode
}

const ButtonRoute: React.FunctionComponent<ButtonRouteProps> = ({
  children,
  to,
  ...others
}) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(to);
  };

  return (
    <Root
    {...others}
    onClick={handleClick}>
        {children}
    </Root>
  );
};

export { ButtonRoute };
