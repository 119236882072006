import React from 'react';

import { Formik } from 'formik';

import { useDispatch, useSelector } from '~/store';
import { showFormFunds, createFunds, updateFunds } from '~/store/fund';

import { Dialog, DialogTitle, DialogContent } from '~/components';

import { ContentForm, validationSchema } from './components';
import { showAlert } from '~/store/alert';


interface FundsFormProps {
  open: boolean;
}

const FundsForm: React.FunctionComponent<FundsFormProps> = ({
  open,
}) => {
  const dispatch = useDispatch();
  const { editData } = useSelector((state) => state.fund);

  const handleClose = () => {
    dispatch(showFormFunds(false));
  };

  const handleSubmit = async (values: any) => {
    try {
      const data = JSON.parse(JSON.stringify(values));
      let portugueseFile = values.portuguese.attachment;
      if (portugueseFile) {
        portugueseFile = new File(
          [portugueseFile],
          portugueseFile.name,
          { type: portugueseFile.type },
        );
        data.portuguese.attachment = portugueseFile;
      }
      await dispatch(data.id ? updateFunds(data) : createFunds(data));
      dispatch(showAlert({
        message: 'Arquivo salvo com sucesso',
      }));
      handleClose();
    } catch (_) {
      dispatch(showAlert({
        message: 'Falha ao realizar a operação',
        type: 'error',
      }));
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth={false}
    >
      <DialogTitle
        title='Cadastrar um Informativo mensal'
        onClose={handleClose}
      />
      <DialogContent>
        <Formik
          initialValues={{
            portuguese: {
              description: '',
              attachment: null,
            },
            ...editData,
          }}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <ContentForm
              isSubmitting={isSubmitting}
            />
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export { FundsForm };
