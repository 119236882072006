import React from 'react';

import { Typography } from '@material-ui/core';

import styled from '@emotion/styled';

import { root } from './home.style';

const Root = styled.div`${root}`;

const Home: React.FunctionComponent = () => (
  <Root>
    <Typography
      color='primary'
      variant="h5">
      Admin do site Jive
      </Typography>
    <Typography
      color='textSecondary'>
      Utilize o menu lateral para gerenciar as páginas dinâmicas do site
      </Typography>
  </Root>
);

export { Home };
