import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import styled from '@emotion/styled';

import { Form, Formik } from 'formik';
import {
  Paper, Button, TextField,
} from '~/components';
import { useSelector, useDispatch } from '~/store';
import { showAlert } from '~/store/alert';

import { root, contentTitle } from './myProfile.style';
import { validationEditPasswordSchema, validationEditSchema } from '~/containers/MyProfile/components';
import { updateProfile, updatePasswordProfile } from '~/store/profile';

const Root = styled(Paper)`${root}`;
const ContentTitle = styled.div`${contentTitle}`;

const MyProfile: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.profile.current);

  const handleSubmit = async (values: any) => {
    try {
      const data = JSON.parse(JSON.stringify(values));
      await dispatch(updateProfile(data));
      dispatch(showAlert({
        message: 'Usuário salvo com sucesso',
      }));
    } catch (_) {
      dispatch(showAlert({
        message: 'Falha ao realizar a operação',
        type: 'error',
      }));
    }
  };


  const handleSubmitPassword = async (values: any) => {
    try {
      const data = JSON.parse(JSON.stringify(values));
      await dispatch(updatePasswordProfile({ ...data, id: user.id }));
      dispatch(showAlert({
        message: 'Usuário salvo com sucesso',
      }));
    } catch (_) {
      dispatch(showAlert({
        message: 'Falha ao realizar a operação',
        type: 'error',
      }));
    }
  };

  return (
      <Root>
        <ContentTitle>
            <Typography
            color='primary'
            variant="h5">
            Meu Perfil
            </Typography>
        </ContentTitle>
        <Formik
          initialValues={user || {}}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={validationEditSchema}
        >
          {({
            isSubmitting,
          }) => (
            <Form>
              <Grid
                spacing={1}
                container>
                <Grid
                  item
                  sm={12}
                  md={12}>
                  <Typography variant='subtitle1'>Alterar dados</Typography>
                  <hr />
                </Grid>
                <Grid
                  spacing={1}
                  container>
                  <Grid
                    item
                    sm={12}
                    md={12}>
                    <TextField
                      label='Nome'
                      name='name'
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={12}>
                    <TextField
                      label='Email'
                      name='email'
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={12}>
                    <Button
                      type='submit'
                      fullWidth
                      large
                      id='submit-basic-profile'
                      color='primary'
                      variant='contained'
                      isSubmitting={isSubmitting}
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        <Formik
          initialValues={{
            password: '',
            passwordConfirm: '',
          }}
          onSubmit={handleSubmitPassword}
          enableReinitialize
          validationSchema={validationEditPasswordSchema}
        >
          {({
            isSubmitting,
          }) => (
            <Form>
              <Grid
                spacing={1}
                container>
                <Grid
                  item
                  sm={12}
                  md={12}>
                  <Typography variant='subtitle1'>Alterar Senha</Typography>
                  <hr />
                </Grid>
                <Grid
                  spacing={1}
                  container>
                  <Grid
                    item
                    sm={12}
                    md={12}>
                    <TextField
                      label='Senha'
                      name='password'
                      type='password'
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={12}>
                    <TextField
                      label='Confirmar Senha'
                      name='passwordConfirm'
                      type='password'
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={12}>
                    <Button
                      type='submit'
                      fullWidth
                      large
                      color='primary'
                      variant='contained'
                      isSubmitting={isSubmitting}
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Root>
  );
};

export { MyProfile };
