
import { Dispatch, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { api } from '../api';

export const Types = {
  LIST_OPPORTUNITIES: 'opportunities/LIST',
  SHOW_FORM_OPPORTUNITIES: 'opportunities/SHOW_FORM',
  CREATE_OPPORTUNITIES: 'opportunities/CREATE',
  UPDATE_OPPORTUNITIES: 'opportunities/UPDATE',
  REMOVE_OPPORTUNITIES: 'opportunities/REMOVE',
};


interface OpportunitiesListAction {
  type: typeof Types.LIST_OPPORTUNITIES,
  payload: any
}

interface OpportunitiesCreateAction {
  type: typeof Types.CREATE_OPPORTUNITIES,
  payload: any
}

interface OpportunitiesUpdateAction {
  type: typeof Types.UPDATE_OPPORTUNITIES,
  payload: any
}

interface OpportunitiesRemoveAction {
  type: typeof Types.REMOVE_OPPORTUNITIES,
  payload: any
}

export interface OpportunityState {
  pagination: {
    docs: any[];
    totalDocs: number;
    page: number;
    totalPages: number;
    hasNextpage: boolean;
    nextPage: number | null;
    hasPrevPage: boolean;
    prevPage: number | null;
    pagingCounter: number;
  };
  params: object;
  showForm: boolean;
  editData: null | object;
}

const initialState: OpportunityState = {
  pagination: {
    docs: [],
    totalDocs: 0,
    page: 1,
    totalPages: 1,
    hasNextpage: false,
    nextPage: null,
    hasPrevPage: false,
    prevPage: null,
    pagingCounter: 1,
  },
  params: {},
  showForm: false,
  editData: null,
};


export default function reducer(
  state = initialState,
  { type, payload }:
  any,
) {
  switch (type) {
    case Types.LIST_OPPORTUNITIES:
      return { ...state, pagination: payload.data, params: payload.params };
    case Types.SHOW_FORM_OPPORTUNITIES:
      return { ...state, showForm: payload.show, editData: payload.data };
    default:
      return state;
  }
}

export const listOpportunities: ActionCreator<
ThunkAction<
Promise<OpportunitiesListAction>,
{},
null,
OpportunitiesListAction
>> = (params) => async (dispatch: Dispatch): Promise<OpportunitiesListAction> => {
  const data = await api.get('/opportunities', {
    params,
  });
  return dispatch({
    payload: {
      data,
      params,
    },
    type: Types.LIST_OPPORTUNITIES,
  });
};

export const createOpportunities: ActionCreator<
ThunkAction<
Promise<OpportunitiesCreateAction>,
{},
null,
OpportunitiesCreateAction
>> = (values: any) => async (dispatch: Dispatch): Promise<OpportunitiesCreateAction> => {
  const result = await api.post('/opportunities', values);
  return dispatch({
    payload: result,
    type: Types.CREATE_OPPORTUNITIES,
  });
};

export const updateOpportunities: ActionCreator<
ThunkAction<
Promise<OpportunitiesUpdateAction>,
{},
null,
OpportunitiesUpdateAction
>> = (values: any) => async (dispatch: Dispatch): Promise<OpportunitiesUpdateAction> => {
  const result = await api.put(`/opportunities/${values.id}`, values);
  return dispatch({
    payload: result,
    type: Types.UPDATE_OPPORTUNITIES,
  });
};

export const removeOpportunities: ActionCreator<
ThunkAction<
Promise<OpportunitiesRemoveAction>,
{},
null,
OpportunitiesRemoveAction
>> = (id: string) => async (dispatch: Dispatch): Promise<OpportunitiesRemoveAction> => {
  await api.delete(`/opportunities/${id}`);
  return dispatch({
    payload: id,
    type: Types.REMOVE_OPPORTUNITIES,
  });
};

export const showFormOpportunities = (show: boolean, data: any = null) => (dispatch: any) => {
  dispatch({ type: Types.SHOW_FORM_OPPORTUNITIES, payload: { show, data } });
};
