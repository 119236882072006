import { css } from '@emotion/core';

const step = ({ active }: any) => css`
  margin: auto;
  width: 80%;
  display: ${active === 'true' ? 'block' : 'none'};  
  
  > div:nth-of-type(1) {
      min-height: 68px;
  }
  
  > div:nth-of-type(3) {
      min-height: 190px;
      
      .MuiFormHelperText-root  {
        padding-top: 4px;
        background-color: white;
      }
  }
 
  
  button {
    margin-bottom: 8px;
  }
`;

export { step };
