import React from 'react';

import { Snackbar as MuiSnackbar } from '@material-ui/core';
import { Alert as Root } from '@material-ui/lab';
import styled from '@emotion/styled';

import { useSelector, useDispatch } from '~/store';
import { clearAlert } from '~/store/alert';

const Snackbar = styled(MuiSnackbar)`
  top: 50% !important;
  max-width: 380px;
`;

const Alert: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { snackbarOpen, snackbarMessage, snackbarType } = useSelector((state) => state.alert);

  function handleClose() {
    dispatch(clearAlert());
  }

  return (
    <Snackbar
    onClose={handleClose}
    open={snackbarOpen}
    autoHideDuration={6000}
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Root
      elevation={6}
      variant="filled"
      severity={snackbarType}
      >
        {snackbarMessage}
      </Root>
    </Snackbar>
  );
};

export { Alert };
