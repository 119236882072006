import React from 'react';

import {
  Grid,
} from '@material-ui/core';

import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js';
import {
  FabAdd, Table,
} from '~/components';
import { useDispatch, useSelector } from '~/store';
import { listOpportunities, removeOpportunities, showFormOpportunities } from '~/store/opportunity';

import { OpportunitiesForm } from './OpportunitiesForm';
import { showConfirmation } from '~/store/confirm';

const OpportunitiesList: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { pagination, showForm, params } = useSelector((state) => state.opportunity);

  const handleAdd = () => {
    dispatch(showFormOpportunities(true));
  };

  const handleEdit = (rowData: any) => {
    const data = JSON.parse(JSON.stringify(rowData));
    if (data.portuguese.text) {
      const contentState = ContentState.createFromBlockArray(
        htmlToDraft(data.portuguese.text).contentBlocks,
      );
      data.portuguese.text = EditorState.createWithContent(contentState);
    }
    if (data.english.text) {
      const contentState = ContentState.createFromBlockArray(
        htmlToDraft(data.english.text).contentBlocks,
      );
      data.english.text = EditorState.createWithContent(contentState);
    }
    dispatch(showFormOpportunities(true, data));
  };

  const handleRemove = (id: string) => {
    dispatch(showConfirmation({
      message: 'Você tem certeza que deseja deletar esse item?',
      proceed: async () => {
        await dispatch(removeOpportunities(id));
        dispatch(listOpportunities(params));
      },
    }));
  };

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      <OpportunitiesForm
        open={showForm} />
      <Table
        title='Oportunidades'
        pagination={pagination}
        serverAction={listOpportunities}
        columns={[
          { label: 'Vaga (Em português) ', name: 'portuguese.title' },
          { label: 'Vaga (Em inglês)', name: 'english.title' },
        ]}
        initialFilters={{
          sort: 'portuguese.title',
        }}
        data={pagination.docs}
        actions={[
          {
            icon: 'add',
            tooltip: 'Adicionar Oportunidade',
            isFreeAction: true,
            onClick: handleAdd,
          },
          {
            icon: 'create',
            tooltip: 'Editar Oportunidade',
            onClick: (rowData: any) => {
              handleEdit(rowData);
            },
          },
          {
            icon: 'delete',
            tooltip: 'Remover Oportunidade',
            onClick: (rowData: any) => {
              handleRemove(rowData.id);
            },
          },
        ]}
      />
      <FabAdd onClick={handleAdd} />
    </Grid>
  );
};

export { OpportunitiesList };
