import React from 'react';


import { Formik } from 'formik';

import { useDispatch } from '~/store';
import { showFormUsers, createUsers } from '~/store/user';

import {
  Dialog, DialogTitle, DialogContent,
} from '~/components';

import { ContentForm, validationSchema } from './components';
import { showAlert } from '~/store/alert';


interface UsersFormProps {
  open: boolean;
}

const UsersForm: React.FunctionComponent<UsersFormProps> = ({
  open,
}) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(showFormUsers(false));
  };

  const handleSubmit = async (values: any) => {
    try {
      const data = JSON.parse(JSON.stringify(values));
      await dispatch(createUsers(data));
      dispatch(showAlert({
        message: 'User salvo com sucesso',
      }));
      handleClose();
    } catch (_) {
      dispatch(showAlert({
        message: 'Falha ao realizar a operação',
        type: 'error',
      }));
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth={false}
    >
      <DialogTitle
        title='Cadastrar um usuário'
        onClose={handleClose}
      />
      <DialogContent>
        <Formik
          initialValues={{
            name: '',
            email: '',
            password: '',
            passwordConfirm: '',
            changePassword: false,
          }}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({
            isSubmitting,
          }) => (
            <ContentForm
              isSubmitting={isSubmitting}
            />
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export { UsersForm };
