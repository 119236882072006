import React from 'react';

import {
  Drawer as Root, Avatar, Hidden, ClickAwayListener, IconButton, Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import CloseIcon from '@material-ui/icons/CloseSharp';

import styled from '@emotion/styled';

import { useSelector, useDispatch } from '~/store';
import { closeDrawer } from '~/store/dashboard';

import { MenuDrawer } from './MenuDrawer';

import {
  header, footer, contentAvatar, closeButton,
} from './drawer.style';
import { Logo, LogoIbaro, IconIbaro } from '~/components';
import { UserMenu } from './UserMenu';

const Header = styled.div`${header}`;
const ContentAvatar: any = styled.div`${contentAvatar}`;
const CloseButton = styled(IconButton)`${closeButton}`;
const Footer: any = styled.div`${footer}`;

interface DrawerProps {
  className: string | undefined;
}

const Drawer: React.FunctionComponent<DrawerProps> = ({
  className,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const openDrawer = useSelector((state) => state.dashboard.drawer.open);

  const handleClickAway = (e: any) => {
    const classList = [...e.srcElement.classList];
    if (openDrawer && !classList.includes('toogle-drawer')) {
      dispatch(closeDrawer());
    }
  };

  const handleClose = () => {
    dispatch(closeDrawer());
  };

  return (
    <>
      <Hidden smDown>
        <Root
          className={className}
          variant="permanent"
          theme={theme}
          open
        >
          <Header>
            <Logo
              width={200}
              height={48}
              className='hide-close' />
          </Header>
          <ContentAvatar
            openDrawer={openDrawer}>
            <Avatar className='avatar' />
            <UserMenu />
          </ContentAvatar>
          <MenuDrawer />
          <Footer
            openDrawer={String(openDrawer)}>
            <IconIbaro
              className='icon'
              width={88}
              height={88}
            />
            <div className='logo'>
              <div>
                <Typography
                  color='textSecondary'
                  variant='caption'>Powered by</Typography>
              </div>
              <LogoIbaro
                width={120}
                height={48}
              />
            </div>
          </Footer>
        </Root>
      </Hidden>
      <Hidden mdUp>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Root
            variant="persistent"
            anchor="left"
            open={openDrawer}
          >
            <Header>
              <Logo
                width={160}
                height={48}
                className='hide-close' />
            </Header>
            <ContentAvatar
              openDrawer={openDrawer}>
              <Avatar className='avatar' />
              <UserMenu />
              <CloseButton
                onClick={handleClose}
              >
                <CloseIcon />
              </CloseButton>
            </ContentAvatar>
            <MenuDrawer />
          </Root>
        </ClickAwayListener>
      </Hidden>
    </>
  );
};


export { Drawer };
