import { css } from '@emotion/core';
import { Theme } from '@material-ui/core';

interface DialogProps {
  theme: Theme
}
const dialog = ({ theme }: DialogProps) => css`
  ${theme.breakpoints.up('lg')} {
    margin-left: 255px;
  }
`;

interface DialogContentProps {
  theme: Theme
}

const dialogContent = ({ theme }: DialogContentProps) => css`
  ${theme.breakpoints.up('sm')} {
    width: 70vw;
  }
  
  ${theme.breakpoints.up('md')} {
    width: 70vw;
    max-width: 1000px;
  }
 `;

interface HeadContentProps {
  noPadding: boolean;
  theme: Theme;
}

const headContent = ({ theme, noPadding }: HeadContentProps) => css`
        padding: 0 0 10px 0;
        display: flex;
        justify-content: space-between;

        button {
            color: ${theme.palette.grey[500]};
        }
        
        ${noPadding ? `
        button {
            padding: 0;
        }
        ` : `
        button {
            margin: 10px 10px 0 0;
        }
        `}
`;

export { headContent, dialogContent, dialog };
