
import { Dispatch, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import axios from 'axios';
import { api } from '../api';

export const Types = {
  LIST_NEWS: 'news/LIST',
  SHOW_FORM_NEWS: 'news/SHOW_FORM',
  CREATE_NEWS: 'news/CREATE',
  UPDATE_NEWS: 'news/UPDATE',
  REMOVE_NEWS: 'news/REMOVE',
};


interface NewsListAction {
  type: typeof Types.LIST_NEWS,
  payload: any
}

interface NewsCreateAction {
  type: typeof Types.CREATE_NEWS,
  payload: any
}

interface NewsUpdateAction {
  type: typeof Types.UPDATE_NEWS,
  payload: any
}

interface NewsRemoveAction {
  type: typeof Types.REMOVE_NEWS,
  payload: any
}

export interface NewState {
  pagination: {
    docs: any[];
    totalDocs: number;
    page: number;
    totalPages: number;
    hasNextpage: boolean;
    nextPage: number | null;
    hasPrevPage: boolean;
    prevPage: number | null;
    pagingCounter: number;
  };
  params: {};
  showForm: boolean;
  editData: any;
}

const initialState: NewState = {
  pagination: {
    docs: [],
    totalDocs: 0,
    page: 1,
    totalPages: 1,
    hasNextpage: false,
    nextPage: null,
    hasPrevPage: false,
    prevPage: null,
    pagingCounter: 1,
  },
  params: {},
  showForm: false,
  editData: null,
};


export default function reducer(
  state = initialState,
  { type, payload }:
  any,
) {
  switch (type) {
    case Types.LIST_NEWS:
      return { ...state, pagination: payload.data, params: payload.params };
    case Types.SHOW_FORM_NEWS:
      return { ...state, showForm: payload.show, editData: payload.data };
    default:
      return state;
  }
}

export const listNews: ActionCreator<
ThunkAction<
Promise<NewsListAction>,
{},
null,
NewsListAction
>> = (params) => async (dispatch: Dispatch): Promise<NewsListAction> => {
  const data = await api.get('/reports', {
    params,
  });
  return dispatch({
    payload: {
      data,
      params,
    },
    type: Types.LIST_NEWS,
  });
};

interface ResultAWS {
  readUrl: string;
  uploadUrl: string
}

const uploadFileAWS = async (attachment: File): Promise<ResultAWS> => {
  const resultAWS: ResultAWS = await api.post('/public-file', {
    mimeType: attachment.type,
  });
  await axios.put(resultAWS.uploadUrl, attachment, { headers: { 'Content-Type': attachment.type } });
  return resultAWS;
};

export const createNews: ActionCreator<
ThunkAction<
Promise<NewsCreateAction>,
{},
null,
NewsCreateAction
>> = (values: any) => async (dispatch: Dispatch): Promise<NewsCreateAction> => {
  const data = { ...values };
  const resultLarge = await uploadFileAWS(data.attachmentLarge);
  data.attachmentLarge = resultLarge.readUrl;
  const resultSmall = await uploadFileAWS(data.attachmentSmall);
  data.attachmentSmall = resultSmall.readUrl;
  const result = await api.post('/reports', data);
  return dispatch({
    payload: result,
    type: Types.CREATE_NEWS,
  });
};

export const updateNews: ActionCreator<
ThunkAction<
Promise<NewsUpdateAction>,
{},
null,
NewsUpdateAction
>> = (values: any) => async (dispatch: Dispatch): Promise<NewsUpdateAction> => {
  const data = { ...values };
  const resultLarge = await uploadFileAWS(data.attachmentLarge);
  data.attachmentLarge = resultLarge.readUrl;
  const resultSmall = await uploadFileAWS(data.attachmentSmall);
  data.attachmentSmall = resultSmall.readUrl;
  const result = await api.put(`/reports/${values.id}`, data);
  return dispatch({
    payload: result,
    type: Types.UPDATE_NEWS,
  });
};

export const removeNews: ActionCreator<
ThunkAction<
Promise<NewsRemoveAction>,
{},
null,
NewsRemoveAction
>> = (id: string) => async (dispatch: Dispatch): Promise<NewsRemoveAction> => {
  await api.delete(`/reports/${id}`);
  return dispatch({
    payload: id,
    type: Types.REMOVE_NEWS,
  });
};

export const showFormNews = (show: boolean, data: any = null) => (dispatch: any) => {
  dispatch({ type: Types.SHOW_FORM_NEWS, payload: { show, data } });
};
