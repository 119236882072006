import React from 'react';

import {
  Grid, Typography,
} from '@material-ui/core';

import styled from '@emotion/styled';

import { Logo, Paper } from '~/components';

import { root } from './forceChangePassword.style';
import { Form } from './components';

const Root = styled(Paper)`${root}`;


const ForceChangePassword: React.FunctionComponent = () => (
    <Root>
        <Grid
            container
            direction='column'
            justify='center'
            alignItems='center'
        >
            <Logo />
            <Typography color='primary' variant='h6'>Redefinir senha</Typography>
            <Typography color='textSecondary' variant='subtitle1'>Redefinição de senha obrigatória</Typography>
            <Form />
        </Grid>
    </Root>
);

export { ForceChangePassword };
