import React from 'react';

import { Grid } from '@material-ui/core';

import { FabAdd, Table } from '~/components';
import { useDispatch, useSelector } from '~/store';
import { listUsers, removeUsers, showFormUsers } from '~/store/user';

import { UsersForm } from './UsersForm';
import { showConfirmation } from '~/store/confirm';
import { UsersEditForm } from '~/containers/Users/UsersEditForm';

const UsersList: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { pagination, showForm, editData } = useSelector((state) => state.user);

  const handleAdd = () => {
    dispatch(showFormUsers(true));
  };

  const handleEdit = (rowData: any) => {
    const data = JSON.parse(JSON.stringify(rowData));
    dispatch(showFormUsers(false, data));
  };

  const handleRemove = (id: string) => {
    dispatch(showConfirmation({
      message: 'Você tem certeza que deseja deletar esse item?',
      proceed: () => {
        dispatch(removeUsers(id));
      },
    }));
  };

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      <UsersForm
        open={showForm} />
      <UsersEditForm
        open={!!editData} />
        <Table
            title={'Usuários'}
            data={pagination.docs}
            pagination={pagination}
            initialFilters={{
              sort: 'name',
            }}
            serverAction={listUsers}
            columns={[
              { label: 'Nome', name: 'name' },
              { label: 'Email', name: 'email' },
            ]}
            actions={[
              {
                icon: 'add',
                tooltip: 'Adicionar Usuário',
                isFreeAction: true,
                onClick: handleAdd,
              },
              {
                icon: 'create',
                tooltip: 'Editar Usuário',
                onClick: (rowData: any) => {
                  handleEdit(rowData);
                },
              },
              {
                icon: 'delete',
                tooltip: 'Remover Usuário',
                onClick: (rowData: any) => {
                  handleRemove(rowData.id);
                },
              },
            ]}
          />
      <FabAdd onClick={handleAdd} />
    </Grid>
  );
};

export { UsersList };
