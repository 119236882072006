import React from 'react';

import { Grid } from '@material-ui/core';

import { FabAdd, Table } from '~/components';
import { useDispatch, useSelector } from '~/store';
import { listHashtags, removeHashtags, showFormHashtags } from '~/store/hashtag';

import { HashtagsForm } from './HashtagsForm';
import { showConfirmation } from '~/store/confirm';

const HashtagsList: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { pagination, showForm, params } = useSelector((state) => state.hashtag);

  const handleAdd = () => {
    dispatch(showFormHashtags(true));
  };

  const handleEdit = (rowData: any) => {
    const data = JSON.parse(JSON.stringify(rowData));
    dispatch(showFormHashtags(true, data));
  };

  const handleRemove = (id: string) => {
    dispatch(showConfirmation({
      message: 'Você tem certeza que deseja deletar esse item?',
      proceed: async () => {
        await dispatch(removeHashtags(id));
        dispatch(listHashtags(params));
      },
    }));
  };

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      <HashtagsForm
        open={showForm} />
      <Table
        title='Hashtags'
        data={pagination.docs}
        pagination={pagination}
        serverAction={listHashtags}
        initialFilters={{
          sort: 'portuguese.description',
        }}
        columns={[
          { label: 'Descrição (Em português)', name: 'portuguese.description' },
          { label: 'Descrição (Em inglês)', name: 'english.description' },
        ]}
        actions={[
          {
            icon: 'add',
            tooltip: 'Adicionar Hashtag',
            isFreeAction: true,
            onClick: handleAdd,
          },
          {
            icon: 'create',
            tooltip: 'Editar Hashtag',
            onClick: (rowData: any) => {
              handleEdit(rowData);
            },
          },
          {
            icon: 'delete',
            tooltip: 'Remover Hashtag',
            onClick: (rowData: any) => {
              handleRemove(rowData.id);
            },
          },
        ]}
      />
      <FabAdd onClick={handleAdd} />
    </Grid>
  );
};

export { HashtagsList };
