
import { Dispatch, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { api } from '../api';

export const Types = {
  LIST_HASHTAGS: 'hashtags/LIST',
  LIST_ALL_HASHTAGS: 'hashtags/LIST_ALL',
  SHOW_FORM_HASHTAGS: 'hashtags/SHOW_FORM',
  CREATE_HASHTAGS: 'hashtags/CREATE',
  UPDATE_HASHTAGS: 'hashtags/UPDATE',
  REMOVE_HASHTAGS: 'hashtags/REMOVE',
};


interface HashtagsListAction {
  type: typeof Types.LIST_HASHTAGS,
  payload: any
}

interface HashtagsListAllAction {
  type: typeof Types.LIST_ALL_HASHTAGS,
  payload: any
}

interface HashtagsCreateAction {
  type: typeof Types.CREATE_HASHTAGS,
  payload: any
}

interface HashtagsUpdateAction {
  type: typeof Types.UPDATE_HASHTAGS,
  payload: any
}

interface HashtagsRemoveAction {
  type: typeof Types.REMOVE_HASHTAGS,
  payload: any
}

export interface HashtagState {
  list: [];
  listAll: [];
  pagination: {
    docs: any[];
    totalDocs: number;
    page: number;
    totalPages: number;
    hasNextpage: boolean;
    nextPage: number | null;
    hasPrevPage: boolean;
    prevPage: number | null;
    pagingCounter: number;
  };
  params: {};
  showForm: boolean;
  editData: null | object;
}

const initialState: HashtagState = {
  list: [],
  listAll: [],
  pagination: {
    docs: [],
    totalDocs: 0,
    page: 1,
    totalPages: 1,
    hasNextpage: false,
    nextPage: null,
    hasPrevPage: false,
    prevPage: null,
    pagingCounter: 1,
  },
  params: {},
  showForm: false,
  editData: null,
};


export default function reducer(
  state = initialState,
  { type, payload }:
  any,
) {
  switch (type) {
    case Types.LIST_HASHTAGS:
      return { ...state, pagination: payload.data, params: payload.params };
    case Types.LIST_ALL_HASHTAGS:
      return {
        ...state,
        listAll: payload.map((i: any) => ({
          portugueseDescription: i.portuguese.description,
          englishDescription: i.english.description,
          ...i,
        })),
      };
    case Types.SHOW_FORM_HASHTAGS:
      return { ...state, showForm: payload.show, editData: payload.data };
    default:
      return state;
  }
}

export const listHashtags: ActionCreator<
ThunkAction<
Promise<HashtagsListAction>,
{},
null,
HashtagsListAction
>> = (params) => async (dispatch: Dispatch): Promise<HashtagsListAction> => {
  const data = await api.get('/hashtags', {
    params,
  });
  return dispatch({
    payload: {
      data,
      params,
    },
    type: Types.LIST_HASHTAGS,
  });
};


export const listAllHashtags: ActionCreator<
ThunkAction<
Promise<HashtagsListAllAction>,
{},
null,
HashtagsListAllAction
>> = () => async (dispatch: Dispatch): Promise<HashtagsListAllAction> => {
  const data = await api.get('/hashtags');
  return dispatch({
    payload: data,
    type: Types.LIST_ALL_HASHTAGS,
  });
};

export const createHashtags: ActionCreator<
ThunkAction<
Promise<HashtagsCreateAction>,
{},
null,
HashtagsCreateAction
>> = (values: any) => async (dispatch: Dispatch): Promise<HashtagsCreateAction> => {
  const result = await api.post('/hashtags', values);
  return dispatch({
    payload: result,
    type: Types.CREATE_HASHTAGS,
  });
};

export const updateHashtags: ActionCreator<
ThunkAction<
Promise<HashtagsUpdateAction>,
{},
null,
HashtagsUpdateAction
>> = (values: any) => async (dispatch: Dispatch): Promise<HashtagsUpdateAction> => {
  const result = await api.put(`/hashtags/${values.id}`, values);
  return dispatch({
    payload: result,
    type: Types.UPDATE_HASHTAGS,
  });
};

export const removeHashtags: ActionCreator<
ThunkAction<
Promise<HashtagsRemoveAction>,
{},
null,
HashtagsRemoveAction
>> = (id: string) => async (dispatch: Dispatch): Promise<HashtagsRemoveAction> => {
  await api.delete(`/hashtags/${id}`);
  return dispatch({
    payload: id,
    type: Types.REMOVE_HASHTAGS,
  });
};

export const showFormHashtags = (show: boolean, data: any = null) => (dispatch: any) => {
  dispatch({ type: Types.SHOW_FORM_HASHTAGS, payload: { show, data } });
};
