import { css } from '@emotion/core';

const root = ({ mobile }: any) => css`
    background-color: ${mobile === 'true' ? 'white' : 'transparent'};
    ${mobile === 'false' ? 'box-shadow: unset;' : ''}
    display: flex;
`;

const contentNav = css`
    flex-grow: 1;
    display: flex;
    align-items: center;
`;

export { root, contentNav };
