import React from 'react';

const LogoMain = require('../../assets/images/icon_ibaro.svg');

interface LogoProps {
  className?: string;
  width?: string | number;
  height?: string | number;
}

const IconIbaro: React.FunctionComponent<LogoProps> = ({
  className,
  height,
  width,
}) => (
    <img
      height={height}
      width={width}
      className={className}
      src={LogoMain}
      alt='Ibaro' />
);

export { IconIbaro };
