import React, { useEffect } from 'react';

import styled from '@emotion/styled';

import { useTheme } from '@material-ui/core/styles';

import { useMediaQuery } from '@material-ui/core';
import { useDispatch, useSelector } from '~/store';

import { Drawer, AppBar } from './components';
import { root } from './dashboard.style';
import { getProfile } from '~/store/profile';

const Root: any = styled.div`
    ${root}
`;

interface DashboardProps {
  children: React.ReactNode
}

const Dashboard: React.FunctionComponent<DashboardProps> = ({ children }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const openDrawer = useSelector((state) => state.dashboard.drawer.open);

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  return (
    <Root
      mobile={mobile}
      theme={theme}
      openDrawer={openDrawer}
    >
      <Drawer
        className={!openDrawer ? 'drawer-close' : 'drawer-open'}
      />
      <AppBar
        className={!openDrawer ? 'appbar-close' : 'appbar-open'}
      />
      <main>
        {children}
      </main>
    </Root>
  );
};

export { Dashboard };
