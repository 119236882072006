import React from 'react';

import {
  IconButton, DialogTitle as MuiDialogTitle, useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import styled from '@emotion/styled';

import { headContent } from './dialog.styles';


const HeadContent: any = styled.div`${headContent}`;

interface DialogTitleProps {
  onClose: () => void;
  title?: React.ReactNode | string;
  noPadding?: boolean;
}

const DialogTitle: React.FunctionComponent<DialogTitleProps> = ({ onClose, noPadding, title }) => {
  const theme = useTheme();

  return (
        <HeadContent
          noPadding={noPadding}
          theme={theme}>
          <MuiDialogTitle>
              {title}
          </MuiDialogTitle>
          <div>
            <IconButton
            aria-label="close"
            onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </HeadContent>
  );
};

export { DialogTitle };
