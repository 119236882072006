import { css } from '@emotion/core';

const activeStyle = ({ theme }: any) => css`
  ::before {
    content: '';
    width: 4px;
    height: 100%;
    background-color: ${theme.palette.primary.main};
    display: block;
    position: absolute;
    left: 0;
    transition: ${theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.standard,
  })};
  }
`;

const listItem = ({ active, theme }: any) => css`
  ::before {
    content: '';
    width: 0;
    height: 100%;
    background-color: black;
    display: block;
    position: absolute;
    left: 0;
  }
  ${active === 'true' ? activeStyle({ theme }) : ''}
`;

const menuItem = ({ active }: any) => css`
  ${active === 'true' ? 'font-weight: bold;' : ''}
`;

export { listItem, menuItem };
