import React from 'react';

import {
  Grid,
} from '@material-ui/core';

import { Form as FormFormik } from 'formik';

import styled from '@emotion/styled';
import { Button, TextField, InputFile } from '~/components';

import { step } from './contentForm.style';

const Step: any = styled(Grid)`${step}`;

interface ContentFormProps {
  isSubmitting: boolean;
  activeStep: number;
  setActiveStep: any;
  validateForm: () => Promise<any>;
  setFieldTouched: (field: string) => void;
}

const ContentForm: React.FunctionComponent<ContentFormProps> = ({
  isSubmitting,
  activeStep,
  setActiveStep,
  validateForm,
  setFieldTouched,
}) => (
    <FormFormik>
      <Step
        container
        spacing={1}
        active={String(activeStep === 0)}
        justify="center"
        alignItems="center"
        direction="column"
      >
        <Grid
          item
          sm={12}
          md={12}>
          <TextField
            label='Nome do Documento (Em português)'
            name='portuguese.description'
            fullWidth
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <InputFile
            label='Documento para download'
            name='portuguese.attachment'
            multiple={false}
            />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
            <Button
              fullWidth
              large
              onClick={() => {
                validateForm().then((res) => {
                  setFieldTouched('portuguese.description');
                  setFieldTouched('portuguese.attachment');
                  if (!res.portuguese) setActiveStep(1);
                });
              }}
              color='primary'
              variant='contained'
            >
              Avançar
          </Button>
        </Grid>
      </Step>
      <Step
        container
        active={String(activeStep === 1)}
        spacing={1}
        direction='row'>
        <Grid
          item
          sm={12}
          md={12}>
          <TextField
            label='Nome do Documento (Em inglês)'
            name='english.description'
            fullWidth
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <InputFile
            label='Documento para download'
            name='english.attachment'
            multiple={false}
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <Button
            type='submit'
            fullWidth
            large
            onClick={() => { setActiveStep(1); }}
            color='primary'
            variant='contained'
            isSubmitting={isSubmitting}
          >
            Salvar
          </Button>
        </Grid>
      </Step>
     </FormFormik>
);

export { ContentForm };
