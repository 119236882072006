/* eslint-disable import/no-cycle */

import { combineReducers } from 'redux';

import auth from './auth';
import alert from './alert';
import notification from './notification';
import dashboard from './dashboard';
import compliance from './compliance';
import fund from './fund';
import event from './event';
import opportunity from './opportunity';
import hashtag from './hashtag';
import news from './news';
import confirm from './confirm';
import user from './user';
import profile from './profile';

const rootReducer = combineReducers({
  auth,
  alert,
  notification,
  dashboard,
  compliance,
  fund,
  event,
  opportunity,
  hashtag,
  news,
  confirm,
  user,
  profile,
});

export default rootReducer;
