import React from 'react';

import {
  TextField as Root,
  TextFieldProps as RootProps,
} from '@startage/react-ui-tools';

import styled from '@emotion/styled';

import { HelperEndAndorment } from './HelperEndAdorment';

import { textField } from './form.styles';

const CustomTextField = styled(Root)`${textField}`;


export interface TextFieldProps extends RootProps {
  helper?: string
  helperDialog?: boolean
}

const TextField: React.FunctionComponent<TextFieldProps> = ({
  name,
  label,
  helper,
  helperDialog,
  endAdornment,
  ...others
}) => {
  const endAdornmentAux = helper ? (
    <HelperEndAndorment
    helper={helper}
    helperDialog={helperDialog}
    />
  ) : endAdornment;

  return (
    <CustomTextField
      name={name}
      label={label}
      endAdornment={endAdornmentAux}
      {...others}
    />
  );
};

export { TextField };
