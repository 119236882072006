
import { Dispatch, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { api } from '../api';
import { USER_KEY } from '~/constants';
import { Types as TypesProfile } from '~/store/profile';


export const Types = {
  USER_FETCHED: 'auth/USER_FETCHED',
  INVALID_USER: 'auth/INVALID_USER',
  USER_REGISTRY: 'auth/USER_REGISTRY',
  RESET: 'auth/RESET',
  CONFIRMATION_EMAIL: 'auth/CONFIRMATION_EMAIL',
  FORGOT_PASSWORD: 'auth/FORGOT_PASSWORD',
  RESET_PASSWORD: 'auth/RESET_PASSWORD',
};


interface UserFetchedAction {
  type: typeof Types.USER_FETCHED,
  payload: any
}

interface LogoutAction {
  type: typeof Types.INVALID_USER,
  payload: any
}

interface UserRegistryAction {
  type: typeof Types.USER_REGISTRY,
  payload: any
}

interface UserConfirmationEmailAction {
  type: typeof Types.CONFIRMATION_EMAIL,
  payload: any
}

interface ForgotPasswordAction {
  type: typeof Types.FORGOT_PASSWORD,
  payload: any
}

interface ResetPasswordAction {
  type: typeof Types.RESET_PASSWORD,
  payload: any
}

// Reducer
let user = localStorage.getItem(USER_KEY);

if (user) user = JSON.parse(user);

interface AuthState {
  user: null | {};
  admin: boolean;
  validToken: boolean;
}

const initialState: AuthState = {
  user,
  admin: false,
  validToken: true,
};


export default function reducer(
  state = initialState,
  { type, payload }:
  any,
) {
  switch (type) {
    case Types.INVALID_USER:
      sessionStorage.removeItem(USER_KEY);
      localStorage.removeItem(USER_KEY);
      return { ...state, validToken: false, user: null };

    case Types.USER_FETCHED:
      localStorage.setItem(USER_KEY, JSON.stringify(payload));
      return { ...state, user: payload, validToken: true };
    case Types.USER_REGISTRY:
      return { ...state };
    case Types.RESET:
      return initialState;
    case TypesProfile.UPDATE_PASSWORD:
      if (!state.user) return state;
      localStorage.setItem(USER_KEY, JSON.stringify({
        ...state.user,
        changePassword: false,
      }));
      return {
        ...state,
        user: {
          ...state.user,
          changePassword: false,
        },
      };
    default:
      return state;
  }
}

interface Login {
  username: string
  password: string
}

export const login: ActionCreator<
ThunkAction<
Promise<UserFetchedAction>,
{},
null,
UserFetchedAction
>> = ({
  username,
  password,
}: Login) => async (dispatch: Dispatch): Promise<UserFetchedAction> => {
  const data = await api.post('/auth/login', {
    username,
    password,
  });
  return dispatch({
    payload: { ...data },
    type: Types.USER_FETCHED,
  });
};


export function logout(): LogoutAction {
  return { type: Types.INVALID_USER, payload: false };
}

interface Registry {
  name: string
  password: string
  email: string
  cpf: string
}

export function registry(values: Registry) {
  return (dispatch: any): Promise<UserRegistryAction> => api.post('/auth/signup', values)
    .then((data: any) => dispatch(
      { type: Types.USER_REGISTRY, payload: data },
    ));
}

interface EmailConfirmation {
  token: string
}

export const emailConfirmation: ActionCreator<
ThunkAction<
Promise<UserConfirmationEmailAction>,
{},
null,
UserConfirmationEmailAction
>> = (values: EmailConfirmation) => async (dispatch: Dispatch) => {
  const data = await api.put(`/auth/signup/confirm/${values.token}`);
  return dispatch({
    payload: data,
    type: Types.CONFIRMATION_EMAIL,
  });
};


interface ForgotPassword {
  email: string
}

export const forgotPassword: ActionCreator<
ThunkAction<
Promise<ForgotPasswordAction>,
{},
null,
ForgotPasswordAction
>> = (values: ForgotPassword) => async (dispatch: Dispatch) => {
  const data = await api.post('/auth/reset-password', values);
  return dispatch({
    payload: data,
    type: Types.FORGOT_PASSWORD,
  });
};
interface ResetPassword {
  token: string
  password: string
}

export const resetPassword: ActionCreator<
ThunkAction<
Promise<ResetPasswordAction>,
{},
null,
ResetPasswordAction
>> = ({
  token,
  password,
}: ResetPassword) => async (dispatch: Dispatch) => {
  const data = await api.put(`/auth/reset-password/${token}`, { password });
  return dispatch({
    payload: data,
    type: Types.RESET_PASSWORD,
  });
};
