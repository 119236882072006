import React, { useEffect } from 'react';

import { Grid } from '@material-ui/core';

import { useParams } from 'react-router';
import { FabAdd, Table } from '~/components';
import { useDispatch, useSelector } from '~/store';
import { listFunds, removeFunds, showFormFunds } from '~/store/fund';

import { FundsForm } from './FundsForm';
import { showConfirmation } from '~/store/confirm';

const FundsList: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { type } = useParams();
  const { list, showForm } = useSelector((state) => state.fund);

  let title = 'Distressed FIM CP IE';


  if (type === 'fim-ii-cp') {
    title = 'Distressed FIM II CP';
  }

  if (type === 'fim-iii-cp') {
    title = 'Distressed FIM III CP';
  }

  useEffect(() => {
    dispatch(listFunds(type));
  }, [dispatch, type]);

  const handleAdd = () => {
    dispatch(showFormFunds(true, { type }));
  };

  const handleEdit = (rowData: any) => {
    const data = JSON.parse(JSON.stringify({ ...rowData, type }));
    if (data.english) {
      data.english.attachment = {
        s3: true,
        url: data.english.attachment,
        name: 'Arquivo enviado anteriormente...',
      };
    }
    if (data.portuguese) {
      data.portuguese.attachment = {
        s3: true,
        url: data.portuguese.attachment,
        name: 'Arquivo enviado anteriormente...',
      };
    }
    dispatch(showFormFunds(true, data));
  };

  const handleRemove = (id: string) => {
    dispatch(showConfirmation({
      message: 'Você tem certeza que deseja deletar esse item?',
      proceed: () => {
        dispatch(removeFunds(id));
      },
    }));
  };

  return (
    <>
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      <FundsForm
        open={showForm} />
      <Table
        title={title}
        columns={[
          { label: 'Mês vigente', name: 'portuguese.description' },
        ]}
        data={list}
        options={{
          pagination: false,
        }}
        actions={[
          {
            icon: 'add',
            tooltip: 'Adicionar Fundo',
            isFreeAction: true,
            onClick: handleAdd,
          },
          {
            icon: 'create',
            tooltip: 'Editar Fundo',
            onClick: (rowData: any) => {
              handleEdit(rowData);
            },
          },
          {
            icon: 'delete',
            tooltip: 'Remover Fundo',
            onClick: (rowData: any) => {
              handleRemove(rowData.id);
            },
          },
        ]}
      />
    </Grid>
    <FabAdd onClick={handleAdd} />
    </>
  );
};

export { FundsList };
