import React from 'react';

import {
  Grid,
} from '@material-ui/core';

import { Form as FormFormik } from 'formik';

import styled from '@emotion/styled';
import { Button, CheckBox, TextField } from '~/components';
import { content } from '~/containers/Users/components/contentForm.style';

const Content = styled(Grid)`${content}`;

interface ContentFormProps {
  isSubmitting: boolean;
}

const ContentForm: React.FunctionComponent<ContentFormProps> = ({
  isSubmitting,
}) => (
    <FormFormik>
       <Content
         spacing={1}
         container>
         <Grid
           item
           sm={12}
           md={12}>
           <TextField
             label='Nome'
             name='name'
             fullWidth
           />
         </Grid>
         <Grid
           item
           sm={12}
           md={12}>
           <TextField
             label='Email'
             name='email'
             autoComplete='off'
             fullWidth
           />
         </Grid>
         <Grid
           item
           sm={12}
           md={12}>
           <TextField
             label='Senha'
             name='password'
             type='password'
             autoComplete='off'
             fullWidth
           />
         </Grid>
         <Grid
           item
           sm={12}
           md={12}>
           <TextField
             label='Confirmar Senha'
             name='passwordConfirm'
             type='password'
             autoComplete='off'
             fullWidth
           />
         </Grid>
         <Grid
           item
           sm={12}
           md={12}>
           <CheckBox
             label='Forçar alteração de senha no primeiro acesso'
             name='changePassword'
           />
         </Grid>
         {/* <Grid */}
         {/*  item */}
         {/*  sm={12} */}
         {/*  md={12}> */}
         {/*  <TextField */}
         {/*    label='Nível de Acesso' */}
         {/*    name='role' */}
         {/*    select */}
         {/*    fullWidth */}
         {/*  > */}
         {/*    <MenuItem value='SUPER_ADMIN'>SUPER ADMIN</MenuItem> */}
         {/*    <MenuItem value='ADMIN'>ADMIN</MenuItem> */}
         {/*  </TextField> */}
         {/* </Grid> */}
         <Grid
           item
           sm={12}
           md={12}>
           <Button
             type='submit'
             fullWidth
             large
             color='primary'
             variant='contained'
             isSubmitting={isSubmitting}
           >
             Salvar
           </Button>
         </Grid>
       </Content>
     </FormFormik>
);

export { ContentForm };
