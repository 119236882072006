import { css } from '@emotion/core';

const header = css`
    padding: 24px;
`;

const contentAvatar = ({ openDrawer }: any) => css`
    padding: ${!openDrawer ? '0 16px' : '0 24px 24px 24px'};

    .avatar {
        margin-bottom: 24px;
    }

    .description {
        color: #A59493;
    }

    .button {
        display: block;
        padding: 0;
    }

    .button:hover {
        background-color: unset;
    }
`;

const closeButton = css`
    position: absolute;
    right: 10px;
    top: 10px;
`;

const footer = ({ openDrawer }: any) => css`
  padding: ${openDrawer === 'true' ? '24px' : '12px 18px'};

  .icon {
    display: ${openDrawer === 'true' ? 'none' : 'block'};
  }

  .logo {
    display: ${openDrawer === 'false' ? 'none' : 'block'};
  }
`;


export {
  header, contentAvatar, closeButton, footer,
};
