import React from 'react';

import { Button as Root, ButtonProps as ButtonMuiProps, CircularProgress } from '@material-ui/core';

/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx } from '@emotion/core';

import { large as largeStyle } from './button.style';


interface ButtonProps extends ButtonMuiProps {
  isSubmitting?: boolean
  large?: boolean
  children: React.ReactNode
}

const Button: React.FunctionComponent<ButtonProps> = ({
  isSubmitting,
  children,
  disabled,
  large,
  ...others
}) => (
        <Root
        {...others}
        css={large ? largeStyle : null}
         disabled={ disabled || isSubmitting}>
            {isSubmitting ? (<CircularProgress size={24} />) : children}
        </Root>
);

export { Button };
