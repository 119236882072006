import React from 'react';

import { Field } from 'formik';

import t from 'typy';

import { Editor as Draft, EditorState } from 'react-draft-wysiwyg';
import { FormHelperText, FormLabel } from '@material-ui/core';

import styled from '@emotion/styled';
import { root } from './editor.style';

const Root = styled.div`${root}`;

interface EditorProps {
  name: string;
  label?: string;
  className?: string;
  options?: object;
  maxLength?: number;
}

const DraftEditor: any = Draft;

const Editor: React.FunctionComponent<EditorProps> = ({
  name,
  label,
  className,
  options = ['inline', 'blockType', 'list', 'textAlign', 'link', 'remove'],
}) => (
  <Field name={name}>
    {
      ({ field, form }: any) => {
        const hasError = t(form.errors, name).safeObject
          && t(form.touched, name).safeObject;
        const errorMessage = hasError ? t(form.errors, name).safeObject : '';

        // @ts-ignore
        // @ts-ignore
        return (
          <Root className={className}>
          { label && (
            <FormLabel>
              {label}
            </FormLabel>
          ) }
            <DraftEditor
              editorState={field.value}
              toolbar={{
                options,
              }}
              fontFamily={{
                options: ['Arial'],
              }}
              onEditorStateChange={(content: EditorState) => {
                // const textLength = content.getCurrentContent().getPlainText().length;
                // if (!maxLength || !(textLength >= maxLength)) {
                field.onChange({
                  target: {
                    name,
                    value: content,
                  },
                });
                // }
              }}
              // handleBeforeInput={() => {
              //   const textLength = field.value.getCurrentContent().getPlainText().length;
              //   if (maxLength && textLength + 1 >= maxLength) return true;
              //   return false;
              // }}
              // handlePastedText={(val: string) => {
              //   const textLength = field.value.getCurrentContent().getPlainText().length;
              //   if (!maxLength) {
              //     return false;
              //   }
              //   return (val.length + textLength) > maxLength;
              // }}
              />
             <FormHelperText error={hasError}>{errorMessage}</FormHelperText>
          </Root>
        );
      }
    }
  </Field>
);

export { Editor };
