import React from 'react';

import { Grid } from '@material-ui/core';

import { format } from 'date-fns';

import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js';
import { FabAdd, Table } from '~/components';
import { useDispatch, useSelector } from '~/store';
import { listNews, removeNews, showFormNews } from '~/store/news';

import { NewsForm } from './NewsForm';
import { base64ToFile, formatDate } from '~/helpers';
import { showConfirmation } from '~/store/confirm';

const NewsList: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { pagination, showForm, params } = useSelector((state) => state.news);

  const handleAdd = () => {
    dispatch(showFormNews(true));
  };

  const handleEdit = async (rowData: any) => {
    const data = JSON.parse(JSON.stringify(rowData));
    data.postedAt = format(new Date(data.postedAt), 'yyyy-MM-dd');
    // data.attachment = {
    //   s3: true,
    //   url: data.attachmentLarge,
    //   name: 'Arquivo enviado anteriormente...',
    // };
    data.attachment = await base64ToFile(
      data.attachmentLarge,
      'Arquivo enviado anteriormente...',
    );
    if (data.portuguese.text) {
      const contentState = ContentState.createFromBlockArray(
        htmlToDraft(data.portuguese.text).contentBlocks,
      );
      data.portuguese.text = EditorState.createWithContent(contentState);
    }
    if (data.english.text) {
      const contentState = ContentState.createFromBlockArray(
        htmlToDraft(data.english.text).contentBlocks,
      );
      data.english.text = EditorState.createWithContent(contentState);
    }
    dispatch(showFormNews(true, data));
  };

  const handleRemove = (id: string) => {
    dispatch(showConfirmation({
      message: 'Você tem certeza que deseja deletar esse item?',
      proceed: async () => {
        await dispatch(removeNews(id));
        dispatch(listNews(params));
      },
    }));
  };

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      <NewsForm
        open={showForm} />
      <Table
        title='Jive na mídia'
        columns={[
          {
            label: 'Data da notícia',
            name: 'postedAt',
            options: {
              customBodyRender: (value: any) => formatDate(new Date(value), 'dd/MM/yyyy'),
            },
          },
          { label: 'Título (Em português)', name: 'portuguese.title' },
          { label: 'Título (Em inglês)', name: 'english.title' },
        ]}
        data={pagination.docs}
        pagination={pagination}
        serverAction={listNews}
        initialFilters={{
          sort: '-postedAt',
        }}
        actions={[
          {
            icon: 'add',
            tooltip: 'Adicionar Notícia',
            isFreeAction: true,
            onClick: handleAdd,
          },
          {
            icon: 'create',
            tooltip: 'Editar Notícia',
            onClick: (rowData: any) => {
              handleEdit(rowData);
            },
          },
          {
            icon: 'delete',
            tooltip: 'Remover Notícia',
            onClick: (rowData: any) => {
              handleRemove(rowData.id);
            },
          },
        ]}
      />
      <FabAdd onClick={handleAdd} />
    </Grid>
  );
};

export { NewsList };
