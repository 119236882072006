import React from 'react';

import styled from '@emotion/styled';

import { root } from './myProfile.style';

import { MyProfile as MyProfileContainer } from '~/containers';

const Root = styled.div`${root}`;

const MyProfile: React.FunctionComponent = () => (
  <Root>
    <MyProfileContainer />
  </Root>
);

export { MyProfile };
