// import { Dispatch, ActionCreator } from 'redux';
// import { ThunkAction } from 'redux-thunk';
// import { api } from '../api';

export const Types = {
  ADD_LIST_NOTIFICATION: 'notification/ADD_LIST_NOTIFICATION',
};


const initialState = {
  list: [],
};


export default function reducer(
  state = initialState,
  { type, payload }:
  any,
) {
  switch (type) {
    case Types.ADD_LIST_NOTIFICATION:
      return {
        ...state,
        list: [...state.list, ...payload],
      };
    default:
      return state;
  }
}
export const addListNotification = (notifications: any) => (dispatch: any) => {
  dispatch({ type: Types.ADD_LIST_NOTIFICATION, payload: notifications });
};
