import React from 'react';

import { useHistory } from 'react-router-dom';

import {
  Grid,
} from '@material-ui/core';

import { Formik, Form as FormFormik } from 'formik';

import { Button, TextField } from '~/components';
import { useDispatch } from '~/store';
import { showAlert } from '~/store/alert';

import { validationSchema } from './schema';
import { updatePasswordProfile } from '~/store/profile';

const Form: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = async ({ password }: any) => {
    try {
      await dispatch(updatePasswordProfile({ password }));
      dispatch(showAlert({
        message: 'Dados alterados com sucesso!',
      }));
      history.push('/home');
    } catch (err) {
      dispatch(showAlert({
        message: 'Falha ao solicitar a redefinição de senha.',
        type: 'error',
      }));
    }
  };

  return (
        <Formik
            initialValues={{
              password: '',
              passwordConfirm: '',
            }}
            onSubmit={handleSubmit}
            enableReinitialize
            validationSchema={validationSchema}
        >
            {({ isSubmitting }) => (
                <FormFormik>
                    <Grid
                        container
                        spacing={1}
                        direction='row'>
                            <Grid item>
                                <TextField
                                    label='Senha'
                                    name='password'
                                    fullWidth
                                    type='password'
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    label='Repetir Senha'
                                    name='passwordConfirm'
                                    fullWidth
                                    type='password'
                                />
                            </Grid>
                        <Grid item>
                            <Button
                                type='submit'
                                color='primary'
                                fullWidth
                                large
                                variant='contained'
                                isSubmitting={isSubmitting}
                            >
                                Redefinir senha
                        </Button>
                        </Grid>
                    </Grid>
                </FormFormik>
            )}
        </Formik>
  );
};

export { Form };
