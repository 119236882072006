/* eslint-disable no-template-curly-in-string */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router';

import { createMuiTheme } from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale';
import { ThemeProvider, StylesProvider } from '@material-ui/styles';

import numeral from 'numeral';
import { createBrowserHistory, History } from 'history';
import { setLocale } from 'yup';

import { Pages } from './pages';

import { store } from '~/store/store';

const theme = createMuiTheme({
  typography: {
    //   fontFamily: 'Nunito',
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      light: '#f6d46f',
      main: '#f1bd1a',
      dark: '#d8a50e',
      contrastText: '#454f5b',
    },
    secondary: {
      light: '#637183',
      main: '#454f5b',
      dark: '#373f49',
      contrastText: '#858788',
    },
    success: {
      light: '#81c784',
      main: '#009B04',
      dark: '#388e3c',
      contrastText: '#fff',
    },
  },
}, ptBR);

setLocale({
  mixed: {
    required: 'Campo obrigatório',
  },
  string: {
    // required: 'Campo obrigatório',
    email: 'Preencha um e-mail válido',
    min: 'Valor muito curto (mínimo ${min} caracteres)',
    max: 'Valor muito longo (máximo ${max} caracteres)',
  },
  number: {
    min: 'Valor inválido (deve ser maior ou igual a ${min})',
    max: 'Valor inválido (deve ser menor ou igual a ${max})',
  },
});

numeral.register('locale', 'pt-br', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'mil',
    million: 'milhões',
    billion: 'b',
    trillion: 't',
  },
  ordinal() {
    return 'º';
  },
  currency: {
    symbol: 'R$',
  },
});

numeral.locale('pt-Br');

const customHistory: History = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <Router history={customHistory}>
          <Pages />
        </Router>
      </StylesProvider>
    </ThemeProvider>
  </Provider>, document.getElementById('root'),
);
