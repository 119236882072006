import React from 'react';

import {
  Menu as MuiMenu, MenuItem, Typography, Grid, Button, Divider,
} from '@material-ui/core';

import styled from '@emotion/styled';

import DropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { useTheme } from '@material-ui/styles';
import { useHistory } from 'react-router';
import { menu } from './userMenu.style';
import { useSelector } from '~/store';

const Menu = styled(MuiMenu)`${menu}`;

const UserMenu: React.FunctionComponent = () => {
  const theme = useTheme();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const user = useSelector((state) => state.profile.current);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goTo = (path: string) => {
    history.push(path);
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        className='button'>
        <Grid container>
          <Typography
            className='hide-close'
            variant='subtitle2'>
            {user.name}
              </Typography>
          <DropDownIcon
            className='hide-close'
            fontSize='small' />
        </Grid>
      </Button>
      <Menu
        theme={theme}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => { goTo('/profile'); }}>Meu Perfil</MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ExitToAppIcon />
          Sair
        </MenuItem>
      </Menu>
    </>
  );
};

export { UserMenu };
