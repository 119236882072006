import React from 'react';

import {
  Autocomplet as Root,
  AutocompletProps as RootProps,
} from '@startage/react-ui-tools';

import styled from '@emotion/styled';

import { textField } from './form.styles';

const CustomTextField = styled(Root)`${textField}`;


const Autocomplet: React.FunctionComponent<RootProps> = ({
  ...others
}) => (
    <CustomTextField
      {...others}
    />
);

export { Autocomplet };
