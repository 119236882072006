import { css } from '@emotion/core';

const root = css`
    height: 100%;
    
    > div {
        height: 100%;
    }
`;

export { root };
