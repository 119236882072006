import React, { useEffect } from 'react';

import {
  Step, Stepper, StepButton, Typography,
} from '@material-ui/core';

import { Formik } from 'formik';

import { useDispatch, useSelector } from '~/store';
import {
  showFormCompliances, createCompliances, updateCompliances, listCompliances,
} from '~/store/compliance';

import {
  Dialog, DialogTitle, DialogContent, ListInfosForm,
} from '~/components';

import { ContentForm, validationSchema } from './components';
import { showAlert } from '~/store/alert';


interface CompliancesFormProps {
  open: boolean;
}

const CompliancesForm: React.FunctionComponent<CompliancesFormProps> = ({
  open,
}) => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const { editData, params } = useSelector((state) => state.compliance);

  useEffect(() => {
    setActiveStep(0);
  }, [open]);

  const handleStep = (newActiveStep: number) => {
    setActiveStep(newActiveStep);
  };

  const handleClose = () => {
    dispatch(showFormCompliances(false));
  };

  const handleSubmit = async (values: any) => {
    try {
      const data = JSON.parse(JSON.stringify(values));
      let portugueseFile = values.portuguese.attachment;
      let englishFile = values.english.attachment;
      if (portugueseFile && !portugueseFile.s3) {
        portugueseFile = new File(
          [portugueseFile],
          portugueseFile.name,
          { type: portugueseFile.type },
        );
        data.portuguese.attachment = portugueseFile;
      }
      if (englishFile && !englishFile.s3) {
        englishFile = new File(
          [englishFile],
          englishFile.name,
          { type: englishFile.type },
        );
        data.english.attachment = englishFile;
      }
      await dispatch(data.id ? updateCompliances(data) : createCompliances(data));
      dispatch(listCompliances(params));
      dispatch(showAlert({
        message: 'Compliance salvo com sucesso',
      }));
      handleClose();
    } catch (_) {
      dispatch(showAlert({
        message: 'Falha ao realizar a operação',
        type: 'error',
      }));
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth={false}
    >
      <DialogTitle
        title='Cadastrar um manual, códigos ou políticas'
        onClose={handleClose}
      />
      <DialogContent>
        <ListInfosForm>
          <li>
            <Typography>
              Utilize os formulários das etapas abaixo para inserir,
              primeiro a versão em português e depois a versão em inglês.
            </Typography>
          </li>
          <li>
            <Typography>
              ATENÇÃO: Caso deixe uma das versões sem conteúdo,
              a mesma não aparecerá no front daquela versão.
              Ex: Se o evento está apenas em português, basta deixar
              a etapa “Inglês” vazia que esse evento aparecerá
              apenas na versão em português e vice-versa.”
            </Typography>
          </li>
        </ListInfosForm>
        <Stepper
          nonLinear
          alternativeLabel
          activeStep={activeStep}>
          <Step>
            <StepButton onClick={() => { handleStep(0); }}>Documento em português</StepButton>
          </Step>
          <Step>
            <StepButton onClick={() => { handleStep(1); }}>Documento em inglês</StepButton>
          </Step>
        </Stepper>
        <Formik
          initialValues={editData || {
            portuguese: {
              description: '',
              attachment: null,
            },
            english: {
              description: '',
              attachment: null,
            },
          }}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({
            isSubmitting,
            validateForm,
            setFieldTouched,
          }) => (
            <ContentForm
              isSubmitting={isSubmitting}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              validateForm={validateForm}
              setFieldTouched={setFieldTouched}
            />
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export { CompliancesForm };
