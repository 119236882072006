import React from 'react';

import { Chip as MuiChip, ChipProps as MuiProps } from '@material-ui/core';

import { useTheme } from '@material-ui/styles';

import styled from '@emotion/styled';

import { root } from './chip.style';


interface ChipProps extends Omit<MuiProps, 'color'> {
  bgColor?: 'inherit' | 'primary' | 'secondary' | 'default' | 'success' | 'warning' | 'error' | 'info'
  colorWeight?: 'light' | 'main' | 'dark'
}

const Root: any = styled(MuiChip)`${root}`;

const Chip: React.FunctionComponent<ChipProps> = ({
  bgColor,
  colorWeight = 'main',
  ...others
}) => {
  const theme = useTheme();

  return (
        <Root
            theme={theme}
            colorWeight={colorWeight}
            bgColor={bgColor}
            {...others}
        />
  );
};

export { Chip };
