import { css } from '@emotion/core';

const step = ({ active }: any) => css`
  margin: auto;
  width: 350px;
  display: ${active === 'true' ? 'block' : 'none'};  
  
  > div:nth-of-type(1) {
      min-height: 68px;
  }
  
  button {
    margin-bottom: 8px;
  }
`;

export { step };
