import { css } from '@emotion/core';

const listRoot = css`
    height: calc(100% - 280px);

    .MuiListItem-root {
      padding-top: 4px;
      padding-bottom: 4px;

      > * {
        font-size: 16px;
      }
    }

    .icon {
        min-width: unset;
        margin-right: 8px;
    }

    .sub-menu {
      padding-left: 48px;
    }
`;

export { listRoot };
