import { css } from '@emotion/core';

const root = css`
  background-color: #eee;
  
  .DraftEditor-root * {
    font-family: Roboto !important;
  }
`;

export { root };
