import { css } from '@emotion/core';

const root = css`
    > div {
        max-width: 420px;
        padding: 32px 16px 8px;
        background-color: white;
    
        img {
            margin-bottom: 24px;
        }
    
        > * {
            padding: 8px;
        }

        > p {
            color: #a7a7a7;
            text-align: center;
            margin-bottom: 16px;
        }

        > form {
            box-sizing: border-box;
            width: 100%;

            > div > div {
                width: 100%;

                :nth-of-type(1), 
                :nth-of-type(2) {
                    min-height: 68px;
                }
            }
        }

        > div:last-of-type {
            margin-top: 48px;
        }
    }
`;

export { root };
