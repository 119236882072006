import { css } from '@emotion/core';

const listInfos = css`
  max-width: 780px;
  margin: auto;
  
  li {
  margin-bottom: 8px;
  }
`;

export { listInfos };
