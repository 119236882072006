import { css } from '@emotion/core';

const root = css`
    max-width: 710px;
    margin: auto;
    
    > div {
      padding: 32px;
    }    
    
`;

export { root };
