import * as yup from 'yup';

export const validationSchema = () => {
  const schema = {
    name: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().required().min(6),
    passwordConfirm: yup.string().required().oneOf([yup.ref('password')], 'O campo senha e confirmar senha devem ser iguais'),
  };
  return yup.object().shape(schema);
};

export const validationEditSchema = () => {
  const schema = {
    name: yup.string().required(),
    email: yup.string().email().required(),
  };
  return yup.object().shape(schema);
};


export const validationEditPasswordSchema = () => {
  const schema = {
    password: yup.string().required().min(6),
    passwordConfirm: yup.string().required().oneOf([yup.ref('password')], 'O campo senha e confirmar senha devem ser iguais'),
  };
  return yup.object().shape(schema);
};
