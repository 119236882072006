import React from 'react';

import { Container, Grid } from '@material-ui/core';

import styled from '@emotion/styled';

import { root } from './hashtags.style';

import { HashtagsList } from '~/containers';

const Root = styled(Container)`${root}`;

const Hashtags: React.FunctionComponent = () => (
  <Root>
    <Grid
      container
      justify='center'
    >
      <HashtagsList />
    </Grid>
  </Root>
);

export { Hashtags };
