import React from 'react';

import {
  Dialog, DialogContent, DialogContentText,
} from '@material-ui/core';

import { DialogTitle } from '~/components';

interface DialogInfoProps {
  open: boolean
  helper: string
  handleClose: () => void
}

const DialogInfo: React.FunctionComponent<DialogInfoProps> = ({
  helper,
  open,
  handleClose,
}) => (
    <Dialog
      open={open}
      onClose={() => handleClose()}
    >
      <DialogContent>
        <DialogTitle
          noPadding
          onClose={() => handleClose()} />
        <DialogContentText>
          {helper}
        </DialogContentText>
      </DialogContent>
    </Dialog>
);

export { DialogInfo };
