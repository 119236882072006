// import { Dispatch, ActionCreator } from 'redux';
// import { ThunkAction } from 'redux-thunk';
// import { api } from '../api';

export const Types = {
  TOOGLE_DRAWER: 'dashboard/TOOGLE_DRAWER',
  CLOSE_DRAWER: 'dashboard/CLOSE_DRAWER',
  OPEN_DRAWER: 'dashboard/OPEN_DRAWER',
};


const initialState = {
  drawer: {
    open: true,
    consumerEvent: true,
  },
};


export default function reducer(
  state = initialState,
  { type, payload }:
  any,
) {
  switch (type) {
    case Types.TOOGLE_DRAWER:
      return {
        ...state,
        drawer: {
          ...state.drawer,
          open: !state.drawer.open,
        },
      };
    case Types.CLOSE_DRAWER:
      return {
        ...state,
        drawer: {
          ...state.drawer,
          open: false,
          consumerEvent: false,
        },
      };
    case Types.OPEN_DRAWER:
      return {
        ...state,
        drawer: {
          ...state.drawer,
          open: true,
          consumerEvent: true,
        },
      };
    default:
      return state;
  }
}
export const toogleDrawer = () => (dispatch: any) => {
  dispatch({ type: Types.TOOGLE_DRAWER, payload: null });
};

export const closeDrawer = () => (dispatch: any) => {
  dispatch({ type: Types.CLOSE_DRAWER, payload: null });
};

export const openDrawer = () => (dispatch: any) => {
  dispatch({ type: Types.OPEN_DRAWER, payload: null });
};
