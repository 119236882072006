import { css } from '@emotion/core';

const step = ({ active }: any) => css`
  margin: auto;
  width: 80%;
  display: ${active === 'true' ? 'block' : 'none'};  
  
  > div:nth-of-type(1),
  > div:nth-of-type(2),
  > div:nth-of-type(3),
  > div:nth-of-type(5),
  > div:nth-of-type(6),
  > div:nth-of-type(7) {
      min-height: 68px;
  }
  
  .editor {
    min-height: 190px;
    
    .MuiFormHelperText-root  {
      padding-top: 4px;
      background-color: white;
    }
  }
  
  button {
    margin-bottom: 8px;
  }
  
  .cropper {
    position: relative;
    width: 100%;
    height: 400px;
    margin-bottom: 8px;
  }
`;

export { step };
