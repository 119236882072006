import React from 'react';

import {
  Grid, Typography,
} from '@material-ui/core';

import { Form as FormFormik } from 'formik';

import styled from '@emotion/styled';
import {
  Button, TextField, Editor,
} from '~/components';

import { step } from './contentForm.style';


const Step: any = styled(Grid)`${step}`;

interface ContentFormProps {
  isSubmitting: boolean;
  activeStep: number;
  setActiveStep: any;
  validateForm: () => Promise<any>;
  setFieldTouched: (field: string) => void;
}

const ContentForm: React.FunctionComponent<ContentFormProps> = ({
  isSubmitting,
  activeStep,
  setActiveStep,
  validateForm,
  setFieldTouched,
}) => (
    <FormFormik>
      <Step
        container
        spacing={1}
        active={String(activeStep === 0)}
        justify="center"
        alignItems="center"
        direction="column"
      >
        <Grid
          item
          sm={12}
          md={12}>
          <TextField
            label='Título da vaga em aberto (em português) com até 80 caracteres'
            name='portuguese.title'
            fullWidth
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <Typography variant='caption'>
            O sistema permite uma certa liberdade na diagramação do texto,
            mas sugerimos cuidado para que essa diagramação não fique fora do
            padrão do site, portanto, sempre limpe a diagramação do texto
            colado usando a ferramenta de apagar (Ícone da borracha).
            Sugerimos também, diagramar conforme exemplo abaixo:
            <br/>
            <br/>

            <b>CONHECIMENTOS OU EXPERIÊNCIAS DESEJÁVEIS:</b>
            <br/>
            - Cursando 3o ou 4o ano de Administração e ou Economia;
            <br/>
            - Disponibilidade para estagiar 6h diárias;
            <br/>
            - Power Point e Excel
            <br/>
            - Inglês Fluente

            <br/>
            <br/>
            <b>PRINCIPAIS ATIVIDADES:</b>
            <br/>
            - Realizar estudos sobre a concorrência, mercado, economia;
            <br/>
            - Auxiliar investidores no processo de investimento;
            <br/>
            - Dar suporte ao processo de Diligência dos Investidores;
            <br/>
            - Apoiar a organização de eventos com investidores.

            <br/>
            <br/>
            <b>LOCAL:</b> Faria Lima -SP
          </Typography>
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <Editor
            name='portuguese.text'
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
            <Button
              fullWidth
              large
              onClick={() => {
                validateForm().then((res) => {
                  setFieldTouched('portuguese.title');
                  setFieldTouched('portuguese.text');
                  if (!res.portuguese) setActiveStep(1);
                });
              }}
              color='primary'
              variant='contained'
            >
              Avançar
          </Button>
        </Grid>
      </Step>
      <Step
        container
        active={String(activeStep === 1)}
        spacing={1}
        direction='row'>
        <Grid
          item
          sm={12}
          md={12}>
          <TextField
            label='Título da vaga em aberto (em inglês) com até 80 caracteres.'
            name='english.title'
            fullWidth
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <Typography variant='caption'>
            O sistema permite uma certa liberdade na diagramação do texto,
            mas sugerimos cuidado para que essa diagramação não fique fora do
            padrão do site, portanto, sempre limpe a diagramação do texto
            colado usando a ferramenta de apagar (Ícone da borracha).
            Sugerimos também, diagramar conforme exemplo abaixo:
            <br/>
            <br/>

            <b>CONHECIMENTOS OU EXPERIÊNCIAS DESEJÁVEIS:</b>
            <br/>
            - Cursando 3o ou 4o ano de Administração e ou Economia;
            <br/>
            - Disponibilidade para estagiar 6h diárias;
            <br/>
            - Power Point e Excel
            <br/>
            - Inglês Fluente

            <br/>
            <br/>
            <b>PRINCIPAIS ATIVIDADES:</b>
            <br/>
            - Realizar estudos sobre a concorrência, mercado, economia;
            <br/>
            - Auxiliar investidores no processo de investimento;
            <br/>
            - Dar suporte ao processo de Diligência dos Investidores;
            <br/>
            - Apoiar a organização de eventos com investidores.

            <br/>
            <br/>
            <b>LOCAL:</b> Faria Lima -SP
          </Typography>
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <Editor
            name='english.text'
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}>
          <Button
            type='submit'
            fullWidth
            large
            onClick={() => { setActiveStep(1); }}
            color='primary'
            variant='contained'
            isSubmitting={isSubmitting}
          >
            Salvar
          </Button>
        </Grid>
      </Step>
     </FormFormik>
);

export { ContentForm };
