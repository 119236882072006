import React from 'react';

import { Formik } from 'formik';

import { useDispatch, useSelector } from '~/store';
import {
  showFormHashtags, createHashtags, updateHashtags, listHashtags,
} from '~/store/hashtag';

import { Dialog, DialogTitle, DialogContent } from '~/components';

import { ContentForm, validationSchema } from './components';
import { showAlert } from '~/store/alert';

interface HashtagsFormProps {
  open: boolean;
}

const HashtagsForm: React.FunctionComponent<HashtagsFormProps> = ({
  open,
}) => {
  const dispatch = useDispatch();
  const { editData, params } = useSelector((state) => state.hashtag);

  const handleClose = () => {
    dispatch(showFormHashtags(false));
  };

  const handleSubmit = async (values: any) => {
    try {
      const data = JSON.parse(JSON.stringify(values));
      await dispatch(data.id ? updateHashtags(data) : createHashtags(data));
      dispatch(listHashtags(params));
      dispatch(showAlert({
        message: 'Hashtag salva com sucesso',
      }));
      handleClose();
    } catch (_) {
      dispatch(showAlert({
        message: 'Falha ao realizar a operação',
        type: 'error',
      }));
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth={false}
    >
      <DialogTitle
        title='Cadastrar uma Hashtag'
        onClose={handleClose}
      />
      <DialogContent>
        <Formik
          initialValues={editData || {
            portuguese: {
              description: '',
            },
            english: {
              description: '',
            },
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <ContentForm
              isSubmitting={isSubmitting}
            />
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export { HashtagsForm };
