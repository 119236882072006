import React from 'react';

import { useTheme } from '@material-ui/styles';

import { CheckBox as Root, CheckBoxProps } from '@startage/react-ui-tools';

/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';

import { checkboxIcon, checkboxCheckedIcon, labelCheckbox } from './form.styles';

const CheckboxIcon = styled.span`${checkboxIcon}`;
const CheckboxCheckedIcon = styled.span`
  ${checkboxIcon}
  ${checkboxCheckedIcon}
`;


const CheckBox: React.FunctionComponent<CheckBoxProps> = ({
  name,
  label,
  ...others
}) => {
  const theme = useTheme();

  return (
    <Root
        css={
          labelCheckbox
        }
        name={name}
        label={label}
        customIcon={<CheckboxIcon />}
        customCheckedIcon={<CheckboxCheckedIcon theme={theme} />}
        {...others}
    />
  );
};

export { CheckBox };
