import React from 'react';

import { Container, Grid } from '@material-ui/core';

import styled from '@emotion/styled';

import { root } from './funds.style';

import { FundsList } from '~/containers';

const Root = styled(Container)`${root}`;

const Funds: React.FunctionComponent = () => (
  <Root>
    <Grid
      container
      justify='center'
    >
      <FundsList
      />
    </Grid>
  </Root>
);

export { Funds };
