import React from 'react';

import Helmet from 'react-helmet';

import styled from '@emotion/styled';

import { Container, Grid } from '@material-ui/core';

import { Login as LoginContainer } from '~/containers';

import { root } from './login.style';

const Root = styled(Container)`${root}`;

const Login: React.FunctionComponent = () => {
  const bodyStyle: any = { style: 'background-color: #eee; margin: 0; height: 100%;' };

  return (
    <Root>
      <Helmet bodyAttributes={bodyStyle} />
      <Grid
        container
        justify='center'
        alignItems='center'
      >
        <LoginContainer />
      </Grid>
    </Root>
  );
};

export { Login };
