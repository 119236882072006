import React from 'react';

import {
  Grid,
} from '@material-ui/core';

import { Formik, Form as FormFormik } from 'formik';

import { useHistory } from 'react-router-dom';

import { Button, TextField } from '~/components';
import { useDispatch } from '~/store';
import { login } from '~/store/auth';
import { showAlert } from '~/store/alert';


import { validationSchema } from './schema';

const Form: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = async (values: any) => {
    try {
      await dispatch(login(values));
      history.push('/home');
    } catch (err) {
      dispatch(showAlert({
        message: 'Usuário ou senha incorretos',
        type: 'error',
      }));
    }
  };

  return (
    <Formik
      initialValues={{ username: '', password: '' }}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <FormFormik>
          <Grid
            container
            spacing={1}
            direction='row'>
            <Grid item>
              <TextField
                label='E-mail'
                name='username'
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                label='Senha'
                name='password'
                fullWidth
                type='password'
              />
            </Grid>
            <Grid item>
              <Button
                type='submit'
                fullWidth
                large
                color='primary'
                variant='contained'
                isSubmitting={isSubmitting}
              >
                Entrar
                        </Button>
            </Grid>
          </Grid>
        </FormFormik>
      )}
    </Formik>
  );
};

export { Form };
